import _ from "lodash";
import { TextareaHTMLAttributes, createRef, useState } from "react";
import {
  BorderProps,
  ColorProps,
  FontProps,
  LineProps,
  propsToClasses,
  restOfProps,
} from "./index_generated";
import "./layout.scss";

type TextAreaProps = {
  classes?: string[];
  onChangeValue: (value: string) => void;
} & TextareaHTMLAttributes<HTMLTextAreaElement> &
  FontProps &
  ColorProps &
  FontProps &
  LineProps &
  BorderProps;

const TextArea = ({
  classes = [],
  onChangeValue,
  style,
  ...props
}: TextAreaProps) => {
  const [focused, setFocused] = useState(false);
  const inputRef = createRef<HTMLTextAreaElement>();

  return (
    <textarea
      ref={inputRef}
      className={_.compact([...propsToClasses(props), ...classes]).join(" ")}
      onChange={(e) => {
        onChangeValue(e.target.value);
      }}
      onClick={() => {
        inputRef.current?.focus();
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
        inputRef.current?.blur();
      }}
      style={{
        color: "#8C8C8C",
        padding: "12px",
        border: `1px solid ${!focused ? "#D9D9D9" : "#004175"}`,

        ...style,
      }}
      {...restOfProps(props)}
    />
  );
};

export default TextArea;
