import { useParams } from "react-router-dom";
import NavBar from "../../common/NavBar/NavBar";
import { useEffect, useState } from "react";
import api from "../../common/api";
import { PrecedentType } from "../Questionnaire/components/Precedent/Precedent";
import VFlex from "../../layouts/VFlex";
import Text from "../../layouts/Text";

function Precedent() {
  const { id } = useParams();
  const [precedent, setPrecedent] = useState<PrecedentType>();
  useEffect(() => {
    (async () => {
      setPrecedent(await api.get(`precedent/${id}`));
    })();
  }, [id]);

  if (!precedent) {
    return <></>;
  }
  return (
    <VFlex g-32 p-16-rl width={"100%"} style={{ maxWidth: 430 }}>
      <NavBar title={`${precedent.caseName} ${precedent.caseId}`} />
      <VFlex g-8>
        <Text t-18>{precedent.holding}</Text>
        <Text t-14>{precedent.headnote}</Text>
        <Text t-12>{precedent.precedent}</Text>
      </VFlex>
    </VFlex>
  );
}

export default Precedent;
