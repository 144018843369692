import NavBar from "../../common/NavBar/NavBar";
import LinkComponent from "../../components/LinkComponent/LinkComponent";
import { useParams } from "react-router-dom";
import VFlex from "../../layouts/VFlex";

const types: Record<string, any[]> = {
  lease: [
    {
      title:
        "임차인이 임대인에게 임대차보증금을 돌려달라고 청구할 수 있는지 궁금해요",
      route: "/questionnaire?questionnaire=1234",
    },
    {
      title:
        "임대인이 임차인/전차인에게 임대차목적물을 돌려달라고 청구할 수 있는지 궁금해요",
      route: "/questionnaire",
      locked: true,
    },
    {
      title: "임차인이 차임 지급을 연체하고 있는 상황에 대해 궁금해요",
      route: "/questionnaire",
      locked: true,
    },
    {
      title:
        "임대차계약이 해지되었는지, 임대차계약을 해지할 수 있는지 궁금해요",
      route: "/questionnaire",
      locked: true,
    },
  ],
};

function Case() {
  const { type } = useParams();
  return (
    <VFlex g-32 p-16-rl width={"100%"} style={{ maxWidth: 430 }}>
      <NavBar title="궁금한점" />
      <VFlex g-8>
        {type &&
          types[type]?.map(({ title, route, locked }, i) => (
            <LinkComponent
              key={i}
              title={title}
              route={route}
              locked={locked}
            />
          ))}
      </VFlex>
    </VFlex>
  );
}

export default Case;
