import _ from "lodash";
import NavBar from "../../common/NavBar/NavBar";
import TextRenderer from "./components/Text/Text";
import DateInputRenderer from "./components/DateInput/DateInput";
import NumberInputRenderer from "./components/NumberInput/NumberInput";
import RadioButtonRenderer from "./components/RadioButton/RadioButton";
import DropdownRenderer from "./components/Dropdown/Dropdown";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Editor from "./Editor";
import StickyButtonRenderer from "./components/StickyButton/StickyButton";
import ResultRenderer from "./components/Result/Result";
import ProvisionRenderer from "./components/Provision/Provision";
import PrecedentRenderer from "./components/Precedent/Precedent";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import api from "../../common/api";
import LoginButtonRenderer from "./components/LoginButton/LoginButton";
import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Button from "../../layouts/Button";
import { useContext } from "react";
import { AppContext } from "../../App";
import Text from "../../layouts/Text";
import TextArea from "../../layouts/TextArea";
import SvgIcon, { SvgIconType } from "../../svg";
import { addDays, addMonths, addWeeks, addYears } from "date-fns";
import { DownloadTemplatePanel } from "./DownloadTemplatePanel";
import { track } from "@amplitude/analytics-browser";

import { useCookies } from "react-cookie";

export type QuestionnaireType = {
  title?: string;
  steps: any[];
  templateName?: string;
};

function ComponentWrapper({
  component,
  store,
  setStore,
  onPressNext,
  completed,
  setAllQA,
}: {
  component: any;
  store: Record<string, any>;
  setStore: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  onPressNext: (targetStep: string, targetPath: string) => void;
  completed: boolean;
  setAllQA: any;
}) {
  const setValue = useCallback(
    (value: any) => {
      component.var &&
        setStore((store) => ({ ...store, [component.var]: value }));
      if (typeof value === "string" || typeof value === "number") {
        setAllQA((prev: any) => prev.set(component.title, value));
      }
    },

    [component.var, component.title, setStore, setAllQA]
  );
  const value = useMemo(
    () => component.var && store[component.var],
    [store, component]
  );

  switch (component.componentName) {
    case "Text":
      return React.createElement(
        TextRenderer,
        { component, value, setValue },
        []
      );
    case "DateInput":
      return React.createElement(
        DateInputRenderer,
        { component, value, setValue },
        []
      );
    case "NumberInput":
      return React.createElement(
        NumberInputRenderer,
        { component, value, setValue },
        []
      );
    case "RadioButton":
      return React.createElement(
        RadioButtonRenderer,
        {
          component,
          value,
          setValue,
        },
        []
      );
    case "Dropdown":
      return React.createElement(
        DropdownRenderer,
        { component, value, setValue },
        []
      );
    case "StickyButton":
      return React.createElement(
        StickyButtonRenderer,
        { component, completed, onPressNext },
        []
      );
    case "Result":
      return React.createElement(
        ResultRenderer,
        { component, value, setValue },
        []
      );
    case "Provision":
      return React.createElement(
        ProvisionRenderer,
        { component, value, setValue },
        []
      );
    case "Precedent":
      return React.createElement(
        PrecedentRenderer,
        { component, value, setValue },
        []
      );
    case "LoginButton":
      return React.createElement(
        LoginButtonRenderer,
        { component, value, setValue, store },
        []
      );
  }
  return <></>;
}

const parseValue = (v: any) => {
  if (v instanceof Date) {
    v = v.getTime();
  } else if (_.isArray(v)) {
    return v;
  } else if (v instanceof Object) {
    v = v.value;
  }
  return v;
};

function Questionnaire() {
  const [, setCookie] = useCookies();
  const { user } = useContext(AppContext)!;
  const [store, setStore] = useState<Record<string, any>>({});
  const getStore = useCallback(
    (store: any) => (key: string) => {
      const v = parseValue(store[key]);
      if (!v) {
        try {
          // eslint-disable-next-line no-new-func
          return Function(
            ..._.keys(store),
            `return ${key}`
          )(..._.keys(store).map((key) => parseValue(store[key])));
        } catch {}
      }
      return v;
    },
    []
  );
  const evaluateConditions = useCallback(
    (getStore: any, conditions: any): boolean => {
      if (!conditions) {
        return true;
      }
      return _.every(
        Object.entries(conditions).map(([key, value]) => {
          const v1 = getStore(key);
          if (_.isObject(value)) {
            return _.every(
              Object.entries(value as Record<string, any>).map(([op, v]) => {
                try {
                  // eslint-disable-next-line no-new-func
                  v = Function(
                    "addDays",
                    "addWeeks",
                    "addMonths",
                    "addYears",
                    `
                  var $now = new Date().getTime()
                  return ${v}
                  `
                  )(addDays, addWeeks, addMonths, addYears);
                } catch {}
                if (op === "$eq") {
                  return v1 === v;
                } else if (op === "$gt") {
                  return v1 > v;
                } else if (op === "$gte") {
                  return v1 >= v;
                } else if (op === "$in") {
                  return !v.includes(v1);
                } else if (op === "$lt") {
                  return v1 < v;
                } else if (op === "$lte") {
                  return v1 <= v;
                } else if (op === "$ne") {
                  return v1 !== v;
                } else if (op === "$nin") {
                  return !v.includes(v1);
                } else if (op === "$and") {
                  return _.every(v, (conditions) =>
                    evaluateConditions(getStore, conditions)
                  );
                } else if (op === "$not") {
                  return !evaluateConditions(getStore, conditions);
                } else if (op === "$nor") {
                  return _.every(
                    v,
                    (conditions) => !evaluateConditions(getStore, conditions)
                  );
                } else if (op === "$or") {
                  return _.some(v, (conditions) =>
                    evaluateConditions(getStore, conditions)
                  );
                }
                return false;
              })
            );
          }
          if (_.isArray(v1)) {
            return v1.includes(value);
          } else {
            return v1 === value;
          }
        })
      );
    },
    []
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { startedByUser } = (state ?? {}) as any;
  const [searchParams, setSearchParams] = useSearchParams();
  const questionnaireId = searchParams.get("questionnaire");
  const inquiryId = searchParams.get("inquiry");
  const editingQuestionnaireId = searchParams.get("edit");
  const [questionnaire, setQuestionnaire] = useState<QuestionnaireType>();
  const [inquiry, setInquiry] = useState<any>();
  const [allQA, setAllQA] = useState(new Map());

  useEffect(() => {
    if (startedByUser && !inquiry?.user && user && !user.anonymous) {
      api.post(`/inquiry/${inquiryId}/claim`);
    }
  }, [startedByUser, inquiry, user, inquiryId]);

  const step = useMemo(
    () =>
      store.stack
        ? questionnaire?.steps.find((step) => step.name === _.last(store.stack))
        : _.first(questionnaire?.steps),
    [questionnaire, store]
  );
  const filteredStore = useMemo(() => {
    const newStore: any = {};
    _.forEach(step?.components, (c) => {
      if (evaluateConditions(getStore(store), c.conditions)) {
        newStore[c.var] = store[c.var];
      }
    });
    return newStore;
  }, [step, store, evaluateConditions, getStore]);
  const getLatestInquiry = useCallback(async () => {
    if (inquiry) {
      return;
    }
    if (inquiryId) {
      const inquiry = await api.get(`/inquiry/${inquiryId}`);
      setStore(inquiry.form);
      setInquiry(inquiry);
      setQuestionnaire({ title: inquiry.title, steps: inquiry.steps });
    } else if (!editingQuestionnaireId) {
      const latestInquiry = await api.get(`/inquiry/latest/${questionnaireId}`);
      if (
        latestInquiry &&
        !latestInquiry.form?.isCompleted &&
        window.confirm("전에 작성하던 질문지가 있습니다. 이어서 하시겠습니까?")
      ) {
        setStore(latestInquiry.form);
        setInquiry(latestInquiry);
        setQuestionnaire({
          title: latestInquiry.title,
          steps: latestInquiry.steps,
        });
      } else {
        const inquiry = await api.post("/inquiry", { questionnaireId });
        setStore(inquiry.form);
        setInquiry(inquiry);
        setQuestionnaire({ title: inquiry.title, steps: inquiry.steps });
      }
    }
  }, [questionnaireId, editingQuestionnaireId, inquiryId, inquiry]);

  useEffect(() => {
    getLatestInquiry();
  }, [getLatestInquiry]);
  const onPressNext = async (targetStep: string, targetPath: string) => {
    if (targetPath) {
      navigate(targetPath, { state });
      return;
    }
    const foundStep = questionnaire?.steps.find(
      (step: any) => step.name === targetStep
    );
    if (!foundStep) {
      console.error(`Step ${targetStep} is not found`);
      return;
    }

    window.scrollTo({ top: 0 });

    if (foundStep.isFinal) {
      const eventProperties = Object.fromEntries(
        Array.from(allQA.entries()).map(([property, value], idx) => [
          `${idx + 1}.${property}`,
          value,
        ])
      );

      // 앰플리튜드 쏘는 부분
      track(questionnaire!.title || "NoTitle", { ...eventProperties });

      const updatedInquiry = await api.get(`/inquiry/${inquiry.id}`);
      searchParams.set("inquiry", inquiry.id);
      setSearchParams(searchParams, { state, replace: true });
      setInquiry(updatedInquiry);
    }
    setStore((store) => ({
      ...store,
      stack: [...(store.stack ?? []), foundStep.name],
      ...(foundStep.isFinal ? { isCompleted: true } : {}),
    }));
  };

  // 현재 작성된 질문지 저장
  useEffect(() => {
    if (inquiry) {
      api.put(`/inquiry/${inquiry.id}`, { form: store });
    }
  }, [inquiry, store]);
  const [completed, setCompleted] = useState(false);

  // 모든 질문지 작성 완료 여부 확인
  useEffect(() => {
    if (!_.isEmpty(step?.components)) {
      setCompleted(
        _.every(
          step.components
            .filter((component: any) => component.required)
            .map((component: any) => !_.isNil(store[component.var]))
        )
      );
    }
  }, [step, store]);

  useEffect(() => {
    if (!store.isCompleted) {
      const fn = (event: any) => {
        event.returnValue = "";
      };
      window.addEventListener("beforeunload", fn);
      return () => {
        window.removeEventListener("beforeunload", fn);
      };
    }
  }, [store]);

  const content = step ? (
    step.components?.map(
      (component: any, i: number) =>
        evaluateConditions(getStore(filteredStore), component.conditions) && (
          <VFlex a-st key={i} width="100%">
            <ComponentWrapper
              component={component}
              store={store}
              setStore={setStore}
              onPressNext={onPressNext}
              completed={completed}
              setAllQA={setAllQA}
            />
          </VFlex>
        )
    )
  ) : (
    <HFlex>
      <div className="Loader-small" />
    </HFlex>
  );

  // useEffect(() => {
  //   if (step && step.name) track("PerSteps", { step: step.name });
  // }, [step]);

  const [question, setQuestion] = useState<string>("");
  const askQuestion = useCallback(async () => {
    const inquiry = await api.post(`inquiry/${inquiryId}/ask`, {
      question,
    });
    setInquiry(inquiry);
    alert("질문이 올라갔습니다.");
  }, [inquiryId, question]);
  useEffect(() => {
    if (inquiry) {
      setQuestion(inquiry.question);
    }
  }, [inquiry]);
  const login = useCallback(() => {
    navigate("/login", {
      state: {
        redirect: encodeURIComponent(window.location.href),
      },
    });
  }, [navigate]);

  const isMobile = useMemo(
    () =>
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ),
    []
  );

  return (
    <>
      {editingQuestionnaireId && (
        <Editor
          questionnaireId={editingQuestionnaireId}
          setQuestionnaire={setQuestionnaire}
        />
      )}
      <VFlex p-16-rl width={!isMobile ? 430 : "100%"}>
        <NavBar
          title={questionnaire?.title}
          onBack={() => {
            if (store.stack?.length > 1) {
              setStore((store) => ({
                ...store,
                stack: store.stack.slice(0, -1),
              }));
            } else {
              navigate(-1);
            }
          }}
        />
        <VFlex g-8 rel c-c width={"100%"}>
          {content}
        </VFlex>
        {inquiry &&
          step?.isFinal &&
          (user ? (
            user.anonymous ? (
              startedByUser && (
                <VFlex g-8>
                  <VFlex g-12 bc-g9 p-16 bdr-16>
                    <Button
                      sized
                      g-4
                      p-16-rl
                      style={{ height: 48 }}
                      type={"secondary"}
                      onClick={() => login()}
                    >
                      <Text f-1 t-14>
                        추가질문을 남겨 변호사 답변 받기
                      </Text>
                      <SvgIcon size={34} icon={SvgIconType.ChevronRight} />
                    </Button>
                    <Button
                      sized
                      g-4
                      p-16-rl
                      style={{ height: 48 }}
                      type={"secondary"}
                      onClick={() => login()}
                    >
                      <Text f-1 t-14>
                        나홀로소송에 필요한 양식 및 절차 받기
                      </Text>
                      <SvgIcon size={34} icon={SvgIconType.ChevronRight} />
                    </Button>
                  </VFlex>
                </VFlex>
              )
            ) : inquiry?.user?.id === user.id ? (
              <VFlex g-8>
                {step?.templates != null && step?.templates.length > 0 && (
                  <VFlex g-8 bc-g9 p-16 bdr-16>
                    <HFlex a-c g-12>
                      <SvgIcon size={32} icon={SvgIconType.Document} />
                      <VFlex>
                        <Text t-18-m>나홀로소송 양식</Text>
                        <Text t-12>
                          답변해주신 내용을 바탕으로 올로가 맞춤양식을
                          제공합니다.<br></br>
                          유료 맞춤양식 속에는 양식 작성팁이 포함되어 있습니다.
                        </Text>
                      </VFlex>
                    </HFlex>
                    <VFlex a-e g-8>
                      <VFlex
                        g-8
                        style={{
                          marginTop: 4,
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {step.templates.map(
                          (template: {
                            templateName: string;
                            templatePrice: number;
                          }) => (
                            <>
                              <DownloadTemplatePanel
                                inquiry={inquiry}
                                inquiryId={inquiryId}
                                setInquiry={setInquiry}
                                setStore={setStore}
                                template={template}
                                user={user}
                              />
                            </>
                          )
                        )}
                      </VFlex>
                      <Text t-10-g7 style={{ width: "fit-content" }}>
                        서비스 제공기간: 온라인상품 구매 후 바로 이용가능
                      </Text>
                    </VFlex>
                  </VFlex>
                )}
                <VFlex g-8 bc-g9 p-16 bdr-16>
                  <HFlex a-c g-12>
                    <SvgIcon size={32} icon={SvgIconType.Question} />
                    <VFlex>
                      <Text t-18-m>올로 변호사에게 질문하기</Text>
                      <Text t-12>
                        더 궁금한 점이 있다면 질문을 남겨주세요.
                        <br />
                        올로 변호사가 직접 대답해드립니다.
                      </Text>
                    </VFlex>
                  </HFlex>
                  <TextArea
                    bd
                    bdr-8
                    dh-85
                    p-12-rl
                    p-8-tb
                    placeholder="올로 변호사에게 질문하기"
                    value={question}
                    onChangeValue={(val) => {
                      setQuestion(val);
                    }}
                  />
                  <HFlex as-st g-8>
                    {inquiry.question?.length > 0 ? (
                      <>
                        <Button f-1 g-4 type="done" caption="질문완료">
                          <SvgIcon size={24} icon={SvgIconType.Check} />
                        </Button>
                        <Button
                          f-1
                          enabled={question?.length > 0}
                          type="confirm"
                          caption="수정하기"
                          onClick={() => askQuestion()}
                        />
                      </>
                    ) : (
                      <Button
                        f-1
                        enabled={question?.length > 0}
                        type="confirm"
                        caption="질문하기"
                        onClick={() => askQuestion()}
                      />
                    )}
                  </HFlex>
                </VFlex>
              </VFlex>
            ) : null
          ) : null)}
        {step?.isFinal && (!user || user.anonymous) && (
          <VFlex
            abs
            style={{
              inset: 0,
              top: 64,
              ...(!user || user.anonymous
                ? {
                    WebkitBackdropFilter: "blur(20px) contrast(100%)",
                    backdropFilter: "blur(20px) contrast(100%)",
                    backgroundBlendMode: "overlay",
                  }
                : {}),
            }}
            c-c
            p-16
          >
            <HFlex as-st>
              <Button
                f-1
                type={"confirm"}
                onClick={() => {
                  setCookie("redirect", location.pathname + location.search);
                  navigate(`/login`, {
                    state: { redirect: window.location.href },
                  });
                }}
                caption="로그인하고 답변 확인하기"
              />
            </HFlex>
          </VFlex>
        )}
        <VFlex height={"20vh"} sized />
      </VFlex>
    </>
  );
}

export default Questionnaire;
