import NavBar from "../../common/NavBar/NavBar";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";
import VFlex from "../../layouts/VFlex";

function PrivacyAgreement() {
  return (
    <VFlex p-16-brl a-c width={"100%"} style={{ maxWidth: 430 }}>
      <HFlex as-st>
        <NavBar profile={false} />
      </HFlex>
      <Text t-22-m>개인정보 수집/이용 동의</Text>
      <Text><br></br><br></br>엠에스(ms)컴퍼니(이하 "회사")가 제공하는 올로(ALLAW) 서비스(이하 "서비스")의 회원가입을 신청하는 분께 수집하는 개인정보의 항목, 수집한 개인정보 이용목적, 보유 및 이용기간 등을 안내 드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>1. 개인정보 수집항목</strong><br></br>
- 회원가입 과정에서 다음와 같은 개인정보를 수집합니다.<br></br>
[일반회원] 이메일 주소, 아이디, 비밀번호, 휴대전화번호<br></br>
[변호사회원] 이메일 주소, 아이디, 비밀번호, 휴대전화번호, 이름, 소속사무소명, 소속사무소 전화번호, 출신시험, 변호사등록증 등록번호, 변호사등록증 발급번호, 프로필사진<br></br>
<br></br>
- 온라인상담 서비스 과정에서 다음과 같은 개인정보를 수집합니다.<br></br>
[일반회원] 상담 요청 시 작성한 닉네임, 상담요청 내역<br></br>
<br></br>
- 나홀로소송 양식제공 서비스 과정에서 다음과 같은 개인정보를 수집합니다.<br></br>
[일반회원] 구매 및 결제 내역, 영수증(카드종류, 카드정보-일부표시)<br></br>
<br></br>
- 고객센터 이용 과정에서 다음과 같은 개인정보를 수집합니다.<br></br>
[일반회원] 이메일주소, 발신전화번호, 문의내용<br></br>
<br></br>
- 서비스 이용 과정에서 다음과 같은 개인정보를 자동으로 수집합니다.<br></br>
PC웹, 모바일웹/앱 이용과정에서의 단말기정보(OS, 화면사이즈, 디바이스 아이디, 단말기 기종, 단말기 모델명), IP주소, 쿠키, 방문일시 부정이용기록, 서비스 이용 기록, 앱 설치 및 사용이력 등의 정보가 자동으로 생성되어 수집될 수 있습니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>2. 수집한 개인정보의 이용목적</strong><br></br>
- 회원가입, 관련 제반 서비스(모바일 웹/앱 포함)의 회원관리, 원활한 고객상담, 이용자와 약속한 서비스 제공, 서비스 구매 및 요금결제, 이용내역 제공, 상품의 제공, 서비스 개발·제공 및 향상 등을 위하여 개인정보를 이용합니다.<br></br>
- 회원 가입 의사의 확인, 이용자 식별, 계정정지 또는 회원탈퇴 의사의 확인, 문의사항 또는 불만처리 등 회원관리를 위하여 개인정보를 이용합니다.<br></br>
- 법령 및 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위 및 비인가 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 고지사항 전달, 분쟁 조정을 위한 기록보존 등 이용자 보호 및 서비스 이용환경을 확인하고 안정적인 운영을 위하여 개인정보를 이용합니다.<br></br>
- 인구통계학적 특성에 따른 서비스 제공, 접속 빈도 분석, 기능개선, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 기반하여 이용자의 상품 구매 및 서비스 이용 성향, 관심, 이용기록 분석 등을 반영한 신규 서비스 제공(개인 맞춤형 상품 추천 서비스 등 포함)등에 개인정보를 이용합니다.<br></br>
- 관련 법령에 따라 통계작성, 과학적 연구, 공익적 기록보존 등을 위하여 개인정보를 가명처리 할 수 있습니다.<br></br>
- 이벤트 정보, 광고성 정보 제공 등 이벤트 및 프로모션의 목적 등에 개인정보를 이용합니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>3. 개인정보의 보유 및 이용기간<br></br>
가. 회사의 개인정보 보유 및 이용기간은 다음과 같습니다.<br></br>
보유항목: 제2조 개인정보 수집항목과 동일<br></br>
보유근거: 회원의 동의(다만, 가명정보는 개인정보보호법의 관련 규정)<br></br>
보유기간: 회원자격 유지기간<br></br>
<br></br>
나. 다만, 회원탈퇴 후에도 하기 정보는 아래의 이유로 명시한 기간 동안 보존합니다.<br></br>
- 서비스 이용에 관한 로그기록<br></br>
보존근거: 통신비밀보호법<br></br>
보존기간: 3개월 이상<br></br>
<br></br>
- 소비자의 불만 또는 분쟁처리에 관한 기록<br></br>
보존근거: 전자상거래 등에서의 소비자보호에 관한 법률<br></br>
보존기간: 3년<br></br>
<br></br>
- 계약 또는 청약철회 등에 관한 기록<br></br>
보존근거: 전자상거래 등에서의 소비자보호에 관한 법률<br></br>
보존기간: 5년<br></br>
<br></br>
- 대금결제 및 재화 등의 공급에 관한 기록<br></br>
보존근거: 전자상거래 등에서의 소비자보호에 관한 법률<br></br>
보존기간: 5년<br></br>
<br></br>
- 건당 거래금액이 1만원을 초과하는 전자금융거래에 관한 기록<br></br>
보존근거: 전자금융거래법<br></br>
보존기간: 5년<br></br>
<br></br>
- 건당 거래금액이 1만원 이하인 전자금융거래에 관한 기록<br></br>
보존근거: 전자금융거래법<br></br>
보존기간: 1년<br></br>
<br></br>
- 서비스 게시물 이용권 취득과 관련해 필요한 항목(서비스 게시물에 기재된 정보)<br></br>
보존근거: 회원의 동의(회원 간 문의 내용 보존 및 회사의 이용권 취득에 따른 이용)<br></br>
보존기간: 해당 게시물에 대해 회사가 취득한 이용권의 이용기간 내<br></br>
<br></br>
- 가명처리된 회원의 이용기록<br></br>
보존근거: 개인정보보호법<br></br>
보존기간: 법령상 허용된 이용 목적 달성시까지</strong><br></br>
<br></br>
이용자는 개인정보 수집 및 이용 동의를 거부할 권리가 있습니다. 다만 개인정보 수집 및 이용 동의를 거부하실 경우 가입 요청하신 서비스 이용이 제한되거나 불가할 수 있습니다.<br></br>
      </Text>
    </VFlex>
  );
}

export default PrivacyAgreement;
