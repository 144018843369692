import { useLocation, useNavigate } from "react-router-dom";
import Text from "../../layouts/Text";
import HFlex from "../../layouts/HFlex";
import { useCallback, useContext, useEffect, useState } from "react";
import api, { baseURL } from "../../common/api";
import Button from "../../layouts/Button";
import TextInput from "../../layouts/TextInput";
import { AppContext } from "../../App";
import SvgIcon, { SvgIconType } from "../../svg";
import NavBar from "../../common/NavBar/NavBar";
import VFlex from "../../layouts/VFlex";
import Image from "../../layouts/Image";

declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}
function Login() {
  const { state } = useLocation();
  const { redirect } = (state ?? {}) as any;
  document.body.style.backgroundColor = "#004175";
  const { setUser, signup } = useContext(AppContext)!;
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const kakaoLogin = useCallback(async () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ message: "kakao_login", redirect })
      );
    } else {
      window.location.href = `${baseURL}auth/kakao${
        redirect ? `?redirect=${encodeURIComponent(redirect)}` : ""
      }`;
    }
  }, [redirect]);

  const naverLogin = useCallback(async () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ message: "naver_login", redirect })
      );
    } else {
      window.location.href = `${baseURL}auth/naver${
        redirect ? `?redirect=${encodeURIComponent(redirect)}` : ""
      }`;
    }
  }, [redirect]);
  const appleLogin = useCallback(async () => {
    window.location.href = `${baseURL}auth/apple${
      redirect ? `?redirect=${encodeURIComponent(redirect)}` : ""
    }`;
  }, [redirect]);
  const login = useCallback(async () => {
    try {
      const user = await api.post(
        redirect
          ? `auth/login/user?redirect=${encodeURIComponent(redirect)}`
          : "auth/login/user",
        {
          username,
          password,
        }
      );
      setUser({ username: username, ...user });
      alert("로그인 성공");
      if (redirect) window.location.href = redirect;
      else navigate("/", { replace: true });
    } catch (e) {
      alert("로그인 실패");
    }
  }, [redirect, username, password, setUser, navigate]);
  const lawyerLogin = useCallback(async () => {
    navigate("/lawyer/signup");
  }, [navigate]);

  const onPasswordChange = useCallback(
    (value: string) => setPassword(value),
    []
  );

  const onIdChange = useCallback((value: string) => {
    setUsername(value);
  }, []);

  const handleLoginMessageEvent = useCallback(
    async (message: any) => {
      const parsedMessage = JSON.parse(message.data);
      if (parsedMessage.type === "kakao_profile") {
        const payload = {
          kakaoId: parsedMessage.kakaoId,
          email: parsedMessage.email,
          nickname: parsedMessage.nickname,
          profileImage: parsedMessage.profileImage,
          thumbnailImage: parsedMessage.thumbnailImage,
          accessToken: parsedMessage.accessToken,
          refreshToken: parsedMessage.refreshToken,
        };
        const res = await api.post(`/auth/kakao/redirect-webview`, payload);

        setUser(res.user);
        if (parsedMessage.redirect) {
          window.location.href = parsedMessage.redirect;
          return;
        }
        navigate(res.redirectUrl);
      } else if (parsedMessage.type === "naver_profile") {
        const payload = {
          naverId: parsedMessage.naverId,
          email: parsedMessage.email,
          nickname: parsedMessage.nickname,
          accessToken: parsedMessage.accessToken,
          refreshToken: parsedMessage.refreshToken,
          profileImage: parsedMessage.profileImage,
        };
        const res = await api.post(`/auth/naver/redirect-webview`, payload);

        setUser(res.user);
        if (parsedMessage.redirect) {
          window.location.href = parsedMessage.redirect;
          return;
        }
        navigate(res.redirectUrl);
      }
    },
    [navigate, setUser]
  );

  useEffect(() => {
    window.addEventListener("message", async (message) => {
      handleLoginMessageEvent(message);
    });

    document.addEventListener("message", async (message: any) => {
      handleLoginMessageEvent(message);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VFlex
      g-20
      p-16-rl
      height={"100%"}
      width={"100%"}
      style={{ position: "relative", paddingBottom: 38, maxWidth: 430 }}
      a-c
    >
      <VFlex>
        <VFlex
          width="100%"
          style={{ position: "absolute", top: 0, left: 0, paddingLeft: 16 }}
        >
          <NavBar profile={false} isLoginPage />
        </VFlex>
      </VFlex>

      <HFlex c-c f-1>
        <SvgIcon width={215} height={42} icon={SvgIconType.LogoTextLarge} />
      </HFlex>

      <VFlex bc-wt-1 p-16 bdr-16 g-10 width={343} height={216}>
        <Text t-18-wt style={{ fontWeight: 600 }}>
          로그인
        </Text>
        <TextInput
          placeholder={"아이디"}
          value={username}
          onChangeValue={onIdChange}
        />
        <TextInput
          placeholder={"비밀번호"}
          value={password}
          type={"password"}
          onChangeValue={onPasswordChange}
        />
        <HFlex as-st>
          <Button
            f-1
            style={{
              width: 311,
              height: 48,
              fontSize: 18,
              fontWeight: 600,
              paddingTop: 2,
            }}
            type={"confirm"}
            onClick={() => login()}
            caption="로그인"
          />
        </HFlex>
      </VFlex>

      <VFlex g-8 p-16-rl a-c j-c width="100%">
        <HFlex g-16 a-s>
          <Button
            g-4
            style={{
              backgroundColor: "#FDDC3F",
              color: "#3A2929",
              width: 40,
              height: 40,
            }}
            type={"confirm"}
            onClick={() => kakaoLogin()}
          >
            <Image src={"images/kakao_login_btn.png"} width={40} height={40} />
          </Button>
          <Button
            g-4
            style={{
              backgroundColor: "#02CB10",
              color: "#3A2929",
              width: 40,
              height: 40,
            }}
            type={"confirm"}
            onClick={() => naverLogin()}
          >
            <Image src={"images/naver_login_btn.png"} width={40} height={40} />
          </Button>
          <Button
            g-4
            style={{
              backgroundColor: "#FFFFFF",
              color: "black",
              width: 40,
              height: 40,
            }}
            type={"confirm"}
            onClick={async () => {
              appleLogin();
            }}
          >
            <SvgIcon
              size={34}
              icon={SvgIconType.LogoApple}
              style={{ marginBottom: 4 }}
            />
          </Button>
        </HFlex>
        <Button
          style={{
            width: 311,
            height: 48,
            fontSize: 18,
            fontWeight: 600,
            marginTop: 12,
          }}
          type={"confirm"}
          onClick={() => signup()}
          caption="회원가입"
        />
        <Button
          style={{ width: 311, height: 48, fontSize: 18, fontWeight: 600 }}
          type={"confirm"}
          onClick={() => lawyerLogin()}
          caption="변호사 회원가입"
        />
      </VFlex>
    </VFlex>
  );
}

export default Login;
