import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext, UserRole } from "../../App";
import HFlex from "../../layouts/HFlex";
import SvgIcon, { SvgIconType } from "../../svg";
import Text from "../../layouts/Text";
import Flex from "../../layouts/Flex";

function NavBar({
  title,
  logo,
  profile = true,
  onBack,
  isLoginPage = false,
}: {
  title?: string;
  logo?: boolean;
  profile?: boolean;
  isLoginPage?: boolean;
  onBack?: () => void;
}) {
  const { user, isLoggingIn } = useContext(AppContext)!;
  const navigate = useNavigate();
  return (
    <HFlex a-c height={64} sized>
      {logo ? (
        <SvgIcon
          icon={SvgIconType.LogoText}
          width={118}
          height={23}
          onClick={() => navigate("/")}
        />
      ) : (
        <>
          <SvgIcon
            icon={
              isLoginPage ? SvgIconType.ArrowBackWhite : SvgIconType.ArrowBack
            }
            size={24}
            onClick={() => (onBack ? onBack() : navigate(-1))}
          />
          <HFlex
            width="100%"
            j-s
            style={{
              marginLeft: 8,
            }}
          >
            <Text t-18 l-1 style={{ fontWeight: 600 }} color="#191C1A">
              {title}
            </Text>
          </HFlex>
        </>
      )}
      <Flex />
      {!!profile &&
        (isLoggingIn || !user ? (
          <HFlex>
            <div className="Loader-small" />
          </HFlex>
        ) : !user.anonymous && user.role === UserRole.LAWYER ? (
          <HFlex onClick={() => navigate("/profile")}>
            <SvgIcon size={40} icon={SvgIconType.LawyerProfile} />
          </HFlex>
        ) : (
          <HFlex onClick={() => navigate("/profile")}>
            <SvgIcon size={40} icon={SvgIconType.AnonymousProfile} />
          </HFlex>
        ))}
    </HFlex>
  );
}

export default NavBar;
