import { ReactElement } from "react";
import provisions from "../../../../data/provision.json";
import Text from "../../../../layouts/Text";
import VFlex from "../../../../layouts/VFlex";
import Divider from "../../../../layouts/Divider";
import ComponentHeader, { ComponentHeaderProps } from "../ComponentHeader";
import { ComponentProps } from "..";

export type ProvisionComponentProps = {
  id: string;
} & ComponentHeaderProps;

function ProvisionRenderer({
  component,
  value,
  setValue,
}: ComponentProps<string, ProvisionComponentProps>): ReactElement {
  const pvs = (provisions as Record<string, any>)[component.id];
  if (!pvs) {
    return <></>;
  }
  return (
    <VFlex bdr-12 bc-g9 p-16 g-4 width="100%">
      <ComponentHeader {...component} title={"관련조문"} />
      <VFlex m-8-t g-16>
        {pvs.map((provision: any, i: number) => (
          <>
            <VFlex key={i} g-8>
              <Text t-14-m>{provision.title}</Text>
              <VFlex p-8-rl>
                {provision.parts.map((part: string, j: number) => (
                  <VFlex key={j}>
                    <Text t-12 dangerouslySetInnerHTML={{ __html: part }} />
                    {j < provision.parts.length - 1 && <Divider />}
                  </VFlex>
                ))}
              </VFlex>
            </VFlex>
            {i < pvs.length - 1 && <Divider />}
          </>
        ))}
      </VFlex>
    </VFlex>
  );
}

export default ProvisionRenderer;
