import { useNavigate } from "react-router-dom";
import HFlex from "../../layouts/HFlex";
import SvgIcon, { SvgIconType } from "../../svg";
import Text from "../../layouts/Text";
import Absolute from "../../layouts/Absolute";

function LinkComponent({
  title,
  route,
  locked,
}: {
  title: string;
  route: string;
  locked: boolean;
}) {
  const navigate = useNavigate();
  return (
    <HFlex
      g-8
      a-c
      bc-g9
      bdr-12
      p-16-rl
      p-12-tb
      onClick={() => navigate(route, { state: { startedByUser: true } })}
      rel
    >
      <Text f-1 t-16>
        {title}
      </Text>
      <SvgIcon icon={SvgIconType.ArrowForward} size={16} />
      {locked && <Absolute bc-wt style={{ opacity: 0.8 }}></Absolute>}
      {locked && (
        <Absolute>
          <HFlex c-c height={"100%"}>
            <SvgIcon icon={SvgIconType.Lock} size={24} />
          </HFlex>
        </Absolute>
      )}
    </HFlex>
  );
}

export default LinkComponent;
