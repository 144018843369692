import { ReactElement, useContext } from "react";
import { AppContext } from "../../../../App";
import Text from "../../../../layouts/Text";
import VFlex from "../../../../layouts/VFlex";
import "./LoginButton.css";

export interface LoginButtonComponent {
  value: string;
}

function LoginButtonRenderer(): ReactElement {
  const { user, isLoggingIn, logout, login } = useContext(AppContext)!;
  if (isLoggingIn) {
    return (
      <VFlex bdr-12 bc-g9 p-16>
        <div className="LoginButton-Loader-body">
          <div className="Loader-small" />
        </div>
      </VFlex>
    );
  }
  if (user) {
    return (
      <VFlex bdr-12 bc-g9 p-16>
        <div className="LoginButton-Profile-body">
          <img
            className="LoginButton-Profile-img"
            //src={user.thumbnailUrl}
            alt="Profile"
          />
          <Text t-22>{"username"}</Text>
          <div className="LoginButton-Profile-logout" onClick={() => logout}>
            <Text t-14>로그아웃</Text>
          </div>
        </div>
      </VFlex>
    );
  }
  return (
    <VFlex bdr-12 bc-g9 p-16>
      <div className="LoginButton-body">
        <img
          onClick={() => login()}
          src="/kakao_login_medium_wide.png"
          alt="Kakao Login"
        />
      </div>
    </VFlex>
  );
}

export default LoginButtonRenderer;
