export const formatPhoneNumberWithDash = (value?: string) => {
  if (!value) {
    return value;
  }

  value = value.replace(/-/g, "");

  let formattedPhone = "";
  if (value.startsWith("010")) {
    formattedPhone = "010";
    if (value.length >= 3) {
      formattedPhone += `-${value.slice(3, 7)}`;
    }
    if (value.length >= 7) {
      formattedPhone += `-${value.slice(7, 11)}`;
    }
    return formattedPhone;
  } else if (value.startsWith("02")) {
    if (value.length > 9) {
      formattedPhone = "02-" + value.slice(2, 6);
    } else if (value.length > 2) {
      formattedPhone = "02-" + value.slice(2, 5);
    } else if (value.length > 3) {
      formattedPhone = value.slice(0, 3) + "-" + value.slice(3, 6);
    } else {
      formattedPhone = value;
    }

    if (value.length > 9) {
      formattedPhone += `-${value.slice(6, 10)}`;
    } else if (value.length > 5) {
      formattedPhone += `-${value.slice(5)}`;
    }
    return formattedPhone;
  } else {
    if (value.length > 10) {
      formattedPhone = value.slice(0, 3) + "-" + value.slice(3, 7);
    } else if (value.length > 3) {
      formattedPhone = value.slice(0, 3) + "-" + value.slice(3, 6);
    } else {
      formattedPhone = value;
    }

    if (value.length > 10) {
      formattedPhone += `-${value.slice(7, 11)}`;
    } else if (value.length > 6) {
      formattedPhone += `-${value.slice(6)}`;
    }

    return formattedPhone;
  }
};

export const formatPhoneNumber = (
  previousValue?: string,
  currentValue?: string
) => {
  if (!currentValue || !previousValue) {
    return currentValue;
  }

  previousValue = previousValue.replace(/-/g, "");
  currentValue = currentValue.replace(/-/g, "");

  let formattedPhone = "";
  if (currentValue.startsWith("010")) {
    formattedPhone = "010";
    if (
      previousValue.length > currentValue.length
        ? currentValue.length > 3
        : currentValue.length >= 3
    ) {
      formattedPhone += `-${currentValue.slice(3, 7)}`;
    }
    if (
      previousValue.length > currentValue.length
        ? currentValue.length > 7
        : currentValue.length >= 7
    ) {
      formattedPhone += `-${currentValue.slice(7, 11)}`;
    }
    return formattedPhone;
  } else if (currentValue.startsWith("02")) {
    if (currentValue.length > 9) {
      formattedPhone = "02-" + currentValue.slice(2, 6);
    } else if (currentValue.length > 2) {
      formattedPhone = "02-" + currentValue.slice(2, 5);
    } else if (currentValue.length > 3) {
      formattedPhone =
        currentValue.slice(0, 3) + "-" + currentValue.slice(3, 6);
    } else {
      formattedPhone = currentValue;
    }

    if (currentValue.length > 9) {
      formattedPhone += `-${currentValue.slice(6, 10)}`;
    } else if (currentValue.length > 5) {
      formattedPhone += `-${currentValue.slice(5)}`;
    }
    return formattedPhone;
  } else {
    if (currentValue.length > 10) {
      formattedPhone =
        currentValue.slice(0, 3) + "-" + currentValue.slice(3, 7);
    } else if (currentValue.length > 3) {
      formattedPhone =
        currentValue.slice(0, 3) + "-" + currentValue.slice(3, 6);
    } else {
      formattedPhone = currentValue;
    }

    if (currentValue.length > 10) {
      formattedPhone += `-${currentValue.slice(7, 11)}`;
    } else if (currentValue.length > 6) {
      formattedPhone += `-${currentValue.slice(6)}`;
    }

    return formattedPhone;
  }
};
