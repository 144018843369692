import _ from "lodash";
import { HTMLAttributes } from "react";
import {
	ButtonType,
	FlexProps,
	MiscellaneousProps,
	propsToClasses,
	restOfProps,
} from "./index_generated";
import "./components.scss";

type ButtonProps = {
	classes?: string[];
	enabled?: boolean;
	type?: ButtonType;
	height?: number;
	caption?: string;
} & HTMLAttributes<HTMLDivElement> &
	FlexProps &
	MiscellaneousProps;

const Button = ({
	classes = [],
	children,
	type = "confirm",
	enabled = true,
	caption,
	height,
	style,
	onClick,
	...props
}: ButtonProps) => {
	return (
		<div
			className={_.compact([
				...propsToClasses(props),
				`${type}-button`,
				enabled ? "" : "disabled",
				...classes,
			]).join(" ")}
			style={{
				...style,
				...(height ? { height: `${height}px` } : {}),
			}}
			onClick={(e) => {
				if (enabled) {
					onClick?.(e);
				}
			}}
			{...restOfProps(props)}
		>
			{children}
			{caption}
		</div>
	);
};

export default Button;
