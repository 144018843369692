import React, { useEffect, useState } from "react";
import api, { baseURL } from "../../common/api";
import Button from "../../layouts/Button";
import HFlex from "../../layouts/HFlex";

function Test() {
  const [inquiry, setInquiry] = useState({ id: 1203123123, paid: false });
  const inquiryId = inquiry.id;
  const templateName = "";

  useEffect(() => {
    // Function to load the Naver Pay script
    const loadNaverPayScript = () => {
      const script = document.createElement("script");
      script.src = "https://nsp.pay.naver.com/sdk/js/naverpay.min.js";
      script.async = true;
      script.onload = () => {
        // Initialize Naver Pay
        //@ts-ignore
        const oPay = Naver.Pay.create({
          mode: "{#_mode}",
          clientId: "{#_clientId}",
          chainId: "{#_chainId}",
        });

        // Get the button or element you want to trigger Naver Pay
        const elNaverPayBtn = document.getElementById("naverPayBtn");

        // Add a click event listener to the button
        elNaverPayBtn?.addEventListener("click", () => {
          oPay.open({
            merchantUserKey: "{#_merchantUserKey}",
            merchantPayKey: "{#_merchantPayKey}",
            productName: "{#_productName}",
            totalPayAmount: "{#_totalPayAmount}",
            taxScopeAmount: "{#_taxScopeAmount}",
            taxExScopeAmount: "{#_taxExScopeAmount}",
            returnUrl: "{#_returnUrl}",
          });
        });
      };

      document.head.appendChild(script);
    };

    // Call the function to load the Naver Pay script
    loadNaverPayScript();
  }, []);

  return (
    <HFlex width="300px" height="100%">
      <Button id="naverPayBtn">네이버 페이</Button>
      <Button
        f-1
        type="secondary"
        caption={inquiry.paid ? `양식 다운로드` : `양식 구매 5000원`}
        onClick={async () => {
          if (inquiry.paid) {
            const id = await api.get(
              `${baseURL}inquiry/${inquiryId}/downloadId`
            );
            window.location.href = `${baseURL}inquiry/${id}/download`;
          } else {
            const payment = await api.post("/payment", {
              templateName,
              amount: 500,
            });
            window.IMP.request_pay(
              {
                pg: "kakaopay",
                merchant_uid: payment.id,
                name: "올로 양식",
                amount: 500,
                buyer_email: "",
                buyer_name: "kipack jeong",
                //   buyer_name: user.name,
                buyer_tel: "",
                buyer_addr: "",
                buyer_postcode: "",
                m_redirect_url: `${baseURL}payment/approve?redirect=${encodeURIComponent(
                  window.location.href
                )}`,
              },
              async (rsp: any) => {
                await api.get(
                  `/payment/approve?imp_uid=${rsp.imp_uid}&merchant_uid=${rsp.merchant_uid}&imp_success=${rsp.success}&error_msg=${rsp.error_msg}`
                );
                const inquiry = await api.get(`/inquiry/${inquiryId}`);
                //   setStore(inquiry.form);
                setInquiry(inquiry);
                if (!rsp.success) {
                  alert("결제 실패");
                }
              }
            );
          }
        }}
      />
    </HFlex>
  );
}

export default Test;
