import _ from "lodash";
import { ReactElement, useState } from "react";
import Select from "react-select";
import Text from "../../../../layouts/Text";
import VFlex from "../../../../layouts/VFlex";
import HFlex from "../../../../layouts/HFlex";
import ComponentHeader, { ComponentHeaderProps } from "../ComponentHeader";
import { ComponentProps } from "..";
import Flex from "../../../../layouts/Flex";
import { SvgIconType } from "../../../../svg";

export type NumberInputComponentProps = {
  var: string;
  min?: number;
  currency?: string;
  description?: string;
  placeholder?: string;
  unit?: string;
  unitOptions?: string[];
  unitPlaceholder?: string;
} & ComponentHeaderProps;

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 40,
    minHeight: 40,
    borderRadius: 8,
  }),
};

function NumberInputRenderer({
  component,
  value,
  setValue,
}: ComponentProps<
  { value?: number; unit?: string },
  NumberInputComponentProps
>): ReactElement {
  const [unit, setUnit] = useState(component.unit);
  return (
    <VFlex bdr-12 bc-g9 p-16 g-8 width="100%">
      <ComponentHeader
        {...component}
        icon={SvgIconType.IconMoney}
        completed={!!value}
      />
      {component.description && <Text t-14-g7>{component.description}</Text>}
      <HFlex>
        <Flex bd bdr-8 height={40}>
          <input
            inputMode="numeric"
            pattern="[0-9]*"
            min={component.min}
            value={(value?.value ?? "").toString()}
            onKeyDown={(e) => {
              if (["-", "+", "e", "."].includes(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              try {
                const n = parseInt(e.target.value);
                setValue({
                  value: _.isNaN(n) ? undefined : n,
                  unit,
                });
              } catch {}
            }}
            placeholder={component.placeholder}
            style={{
              width: "100%",
              height: "100%",
              padding: "0px 10px",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              borderRadius: "8px",
            }}
          />
        </Flex>
        {component.unit && (
          <HFlex a-c m-8-l>
            <Text>{unit}</Text>
          </HFlex>
        )}
        {component.unitOptions && (
          <HFlex a-c m-8-l width={60}>
            <Select
              menuPortalTarget={document.body}
              placeholder={component.unitPlaceholder}
              styles={customStyles}
              isSearchable={false}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              onChange={(item) => setUnit(item?.value)}
              options={component.unitOptions.map((value) => ({
                value,
                label: value,
              }))}
            />
          </HFlex>
        )}
      </HFlex>
    </VFlex>
  );
}

export default NumberInputRenderer;
