import { ReactElement } from "react";
import { ComponentProps } from "..";
import Text from "../../../../layouts/Text";
import { ComponentHeaderProps } from "../ComponentHeader";

export type TextComponentProps = {
  value: string;
  fontSize: number;
} & ComponentHeaderProps;

function TextRenderer({
  component,
  value,
  setValue,
}: ComponentProps<string, TextComponentProps>): ReactElement {
  return (
    <Text style={{ fontSize: component.fontSize }}>{component.value}</Text>
  );
}

export default TextRenderer;
