import NavBar from "../../common/NavBar/NavBar";
import { useLocation, useNavigate } from "react-router-dom";
import Text from "../../layouts/Text";
import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import { useCallback, useContext, useEffect, useState } from "react";
import api from "../../common/api";
import Button from "../../layouts/Button";
import TextInput from "../../layouts/TextInput";
import Flex from "../../layouts/Flex";
import { AppContext } from "../../App";
import { formatPhoneNumber } from "../../common/util";
import { useCookies } from "react-cookie";

function SignupL2() {
  const [cookies, , removeCookie] = useCookies();
  const { setUser } = useContext(AppContext)!;
  const navigate = useNavigate();
  const [phone, setPhone] = useState<string>();
  const [code, setCode] = useState<string>("");
  const [wrongCode, setWrongCode] = useState<string>();
  const [error, setError] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [readyToSendCode, setReadyToSendCode] = useState(false);
  const [readyToVerifyCode, setReadyToVerifyCode] = useState(false);
  const [sentVerification, setSentVerification] = useState(false);
  const [sentCode, setSentCode] = useState(false);

  useEffect(() => {
    if (timeLeft !== 0) {
      const timer = setInterval(() => {
        // Decrease timeLeft by 1 second (1000 milliseconds)
        setTimeLeft((prevTime) => prevTime - 1000);

        if (timeLeft === 1000) {
          clearInterval(timer);
          setSentCode(false);
          setReadyToSendCode(true);
        }
      }, 1000);

      // Clean up the timer when the component unmounts
      return () => {
        clearInterval(timer);
      };
    }
  }, [timeLeft]);
  useEffect(() => {
    if (sentVerification) {
      const timer = setInterval(() => {
        // Decrease timeLeft by 1 second (1000 milliseconds)
        setTimeLeft((prevTime) => prevTime - 1000);

        // When timeLeft reaches 0, clear the interval
        if (timeLeft === 1000) {
          clearInterval(timer);
          setReadyToVerifyCode(true);
          setSentVerification(false);
        }
      }, 3000);

      // Clean up the timer when the component unmounts
      return () => {
        clearInterval(timer);
      };
    }
  }, [sentVerification, timeLeft]);
  useEffect(() => {
    setReadyToSendCode(phone != null && phone.length > 9 && !error);
  }, [error, phone]);

  const minutes = Math.floor(timeLeft / 60000);
  const seconds = ((timeLeft % 60000) / 1000).toFixed(0);

  const { state } = useLocation();
  useEffect(() => {
    setReadyToVerifyCode(
      phone?.length! > 9 && code.length === 6 && code !== wrongCode
    );
  }, [code, phone?.length, readyToSendCode, wrongCode]);

  const submitCode = useCallback(
    async (code: string) => {
      try {
        const { username, email, password, agreed4 } = state as any;
        setSentVerification(true);
        setReadyToVerifyCode(false);
        await api.post("auth/otp/verify", { code, phone });
        await api.post("user", {
          username,
          password,
          email,
          phone: phone?.replaceAll("-", ""),
          agreedMarketing: !!agreed4,
        });
        const user = await api.post("auth/login/user", { username, password });
        setUser(user);
        alert("인증되었습니다.");
        if (cookies["redirect"]) {
          navigate(cookies["redirect"]);
          removeCookie("redirect");
        } else {
          navigate("/");
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          alert("인증코드가 틀렸습니다.");
          setWrongCode(code);
          setError(true);
        } else {
          alert("예상치 못한 문제가 발생했습니다. 다시 시도해주세요");
        }
      }
    },
    [cookies, navigate, phone, removeCookie, setUser, state]
  );
  return (
    <VFlex a-c width={"100vw"} height={"100vh"} style={{ background: "#FFF" }}>
      <VFlex g-32 width={"100%"} style={{ maxWidth: 430 }} p-16-brl>
        <NavBar profile={false} title={"휴대전화번호 입력"} />
        <VFlex g-16 style={{ marginTop: 19 }}>
          <HFlex g-8 a-e>
            <TextInput
              f-1
              caption="휴대전화번호"
              placeholder={"휴대전화번호"}
              value={phone}
              inputMode="numeric"
              onKeyDown={(e: any) => {
                if (!(e.key === "Backspace" || /[0-9]/.test(e.key))) {
                  e.preventDefault();
                }
              }}
              onChangeValue={(value) =>
                setPhone((phone) => formatPhoneNumber(phone, value))
              }
            />
            <Button
              type={"primary"}
              caption="코드전송"
              enabled={readyToSendCode}
              c-g5
              style={{
                width: 98,
              }}
              onClick={async () => {
                await api.get(`/auth/otp?phone=${phone?.replaceAll("-", "")}`);
                setTimeLeft(1000 * 60 * 3);
                setReadyToSendCode(false);
                setSentCode(true);
                alert("코드가 전송되었습니다.");
              }}
            />
          </HFlex>
          <HFlex g-8 a-e>
            <TextInput
              f-1
              t-16
              caption="인증번호 입력"
              type="number"
              placeholder={"인증번호"}
              value={code}
              onChangeValue={(newCode) => {
                if (newCode.length < 7) {
                  setCode((prev) => {
                    if (prev.length !== newCode.length) {
                      setError(false);
                    }
                    return newCode;
                  });
                }
              }}
              inputMode="numeric"
              error={error}
            />
            <Button
              type={"primary"}
              caption="인증"
              enabled={readyToVerifyCode}
              c-g5
              style={{
                width: 98,
              }}
              onClick={async () => {
                submitCode(code);
              }}
            />
          </HFlex>
        </VFlex>
        <Flex />
        {sentCode && (
          <VFlex a-c>
            <Text t-14>
              코드 다시 보내기: {minutes}:
              {seconds.length === 1 ? `0${seconds}` : seconds}
            </Text>
          </VFlex>
        )}
      </VFlex>
    </VFlex>
  );
}

export default SignupL2;
