import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./App.scss";
import { Routes, Route, useNavigate } from "react-router-dom";
import Case from "./scenes/Case/Case";
import Questionnaire from "./scenes/Questionnaire/Questionnaire";
import Precedent from "./scenes/Precedent/Precedent";
import Main from "./scenes/Main/Main";
import api from "./common/api";
import HFlex from "./layouts/HFlex";
import { SetStateAction } from "react";
import { Dispatch } from "react";
import Login from "./scenes/Main/Login";
import Profile from "./scenes/Main/Profile";
import Signup from "./scenes/Main/Signup";
import LawyerSignup from "./scenes/Lawyer/LawyerSignup";
import SignupL2 from "./scenes/Main/SignupL2";
import LawyerInquiry from "./scenes/Lawyer/LawyerInquiry";
import LawyerSignupL2 from "./scenes/Lawyer/LawyerSignupL2";
import LawyerSignupL3 from "./scenes/Lawyer/LawyerSignupL3";
import Privacy from "./scenes/Main/Privacy";
import TermsOfService from "./scenes/Main/TermsOfService";
import MarketingAgreement from "./scenes/Main/MarketingAgreement";
import Onboarding from "./scenes/Onboarding";
import PrivacyAgreement from "./scenes/Main/PrivacyAgreement";
import Test from "./scenes/Main/Test";
import { CookiesProvider } from "react-cookie";
import * as amplitude from "@amplitude/analytics-browser";
import { EditLawyerProfile } from "./scenes/Main/EditLawyerProfile";
declare global {
  interface Window {
    IMP: any;
  }
}

export enum UserRole {
  LAWYER = "lawyer",
  ADMIN = "admin",
  USER = "user",
}

type AuthenticatedUser = {
  id: string;
  username: string;
  local: Object;
  name: string;
  email: string;
  phone: string;
  profileImage: string;
  thumbnailImage: string;
  anonymous: false;
  role: UserRole;
  approved: boolean;
  organization: string;
  organizationPhone: string;
  educationCareerHistory?: string;
  test: string;
  testNumber: string;
};

type User =
  | AuthenticatedUser
  | {
      anonymous: true;
    };

type AppContextInterface = {
  user: User | undefined;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  logout: () => void;
  login: () => void;
  signup: () => void;
  isLoggingIn: boolean;
};
export const AppContext = React.createContext<AppContextInterface | undefined>(
  undefined
);

function App() {
  document.body.style.backgroundColor = "#f8f8f8";
  const navigate = useNavigate();
  const [user, setUser] = useState<User>();
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  useEffect(() => {
    try {
      amplitude.init("ac6dd99254ab15f04959556c6d52355f", {
        defaultTracking: true,
      });

      window.IMP.init("imp37405014");
    } catch (error) {
      console.error("App initialization failed", error);
    }
  }, []);
  const login = useCallback(() => {
    navigate("/login");
  }, [navigate]);
  const signup = useCallback(() => {
    navigate("/signup");
  }, [navigate]);
  const logout = useCallback(async () => {
    await api.post("/auth/logout");
    setUser({ anonymous: true });
  }, []);
  const getUser = useCallback(async () => {
    try {
      setIsLoggingIn(true);
      const user = await api.get(`/user`);
      setUser(user);
    } catch (e) {
      setUser({ anonymous: true });
    } finally {
      setIsLoggingIn(false);
    }
  }, [setUser, setIsLoggingIn]);

  useEffect(() => {
    getUser();
  }, [getUser]);
  const context = useMemo(
    () => ({ user, setUser, logout, login, signup, isLoggingIn }),
    [user, setUser, logout, login, signup, isLoggingIn]
  );

  return (
    <AppContext.Provider value={context}>
      <CookiesProvider>
        <HFlex as-st j-c style={{ height: "100%" }}>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="otp" element={<SignupL2 />} />
            <Route path="" element={<Main />} />
            <Route path="profile" element={<Profile />} />
            <Route path="profile/lawyer/edit" element={<EditLawyerProfile />} />
            <Route path="case/:type" element={<Case />} />
            <Route path="questionnaire" element={<Questionnaire />} />
            <Route path="precedent/:id" element={<Precedent />} />
            <Route path="lawyer/inquiry/:id" element={<LawyerInquiry />} />
            <Route path="onboarding" element={<Onboarding />} />
            <Route path="lawyer/signup" element={<LawyerSignup />} />
            <Route path="lawyer/signup/otp" element={<LawyerSignupL2 />} />
            <Route path="lawyer/signup/info" element={<LawyerSignupL3 />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="privacy_agreement" element={<PrivacyAgreement />} />
            <Route path="termsofservice" element={<TermsOfService />} />
            <Route path="marketing" element={<MarketingAgreement />} />
            <Route path="test" element={<Test />} />
          </Routes>
        </HFlex>
      </CookiesProvider>
    </AppContext.Provider>
  );
}

export default App;
