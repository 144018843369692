import { ReactElement } from "react";
import Select, { SingleValue } from "react-select";
import VFlex from "../../../../layouts/VFlex";
import ComponentHeader, { ComponentHeaderProps } from "../ComponentHeader";
import { ComponentProps } from "..";
import Text from "../../../../layouts/Text";

export type DropdownComponentProps = {
  var: string;
  options: string[];
  description?: string;
  vertical: boolean;
} & ComponentHeaderProps;

function DropdownRenderer({
  component,
  value,
  setValue,
}: ComponentProps<string, DropdownComponentProps>): ReactElement {
  const onSelectChange = (
    newValue: SingleValue<{ value: string; label: string }>
  ) => {
    setValue(newValue?.value);
  };
  return (
    <VFlex bdr-12 bc-g9 p-16 width="100%">
      <ComponentHeader {...component} />
      {component.description && <Text t-14-g7>{component.description}</Text>}
      <VFlex m-8-t width={"100%"}>
        <Select
          isSearchable={false}
          menuPortalTarget={document.body}
          placeholder="선택해주세요"
          value={value ? { value, label: value } : undefined}
          onChange={onSelectChange}
          options={component.options.map((value) => ({ value, label: value }))}
        />
      </VFlex>
    </VFlex>
  );
}

export default DropdownRenderer;
