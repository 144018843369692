import _ from "lodash";
import { ReactElement, useEffect, useState } from "react";
import Select from "react-select";
import VFlex from "../../../../layouts/VFlex";
import HFlex from "../../../../layouts/HFlex";
import Flex from "../../../../layouts/Flex";
import ComponentHeader, { ComponentHeaderProps } from "../ComponentHeader";
import { ComponentProps } from "..";
import Text from "../../../../layouts/Text";
import { SvgIconType } from "../../../../svg";

export type DateInputProps = {
  var: string;
  description?: string;
  range?: [number, number];
} & ComponentHeaderProps;

function DateInputRenderer({
  component,
  value,
  setValue,
}: ComponentProps<Date, DateInputProps>): ReactElement {
  const [dayOptions, setDayOptions] = useState<
    { value: number; label: number }[]
  >([]);
  const date = value ? new Date(value) : undefined;
  const [year, setYear] = useState<number | undefined>(date?.getFullYear());
  const [month, setMonth] = useState<number | undefined>(
    date ? date.getMonth() + 1 : undefined
  );
  const [day, setDay] = useState<number | undefined>(date?.getDate());
  useEffect(() => {
    if (_.isNumber(year) && _.isNumber(month) && _.isNumber(day)) {
      setValue(new Date(year, month, day));
    }
  }, [setValue, year, month, day]);
  return (
    <VFlex bdr-12 bc-g9 p-16 width="100%">
      <ComponentHeader
        {...component}
        icon={SvgIconType.IconCalendar}
        completed={!!value}
      />
      {component.description && <Text t-14-g7>{component.description}</Text>}
      <HFlex g-8 m-8-t>
        <Flex>
          <Select
            menuPortalTarget={document.body}
            placeholder="년도"
            isSearchable={false}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            value={day ? { value: year, label: year } : undefined}
            onChange={(item) => setYear(item?.value)}
            options={_.range(
              new Date().getFullYear() + (component.range?.[0] ?? -150),
              new Date().getFullYear() + (component.range?.[1] ?? 5) + 1
            )
              .reverse()
              .map((value) => ({ value, label: value }))}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: "8px",
              }),
            }}
          />
        </Flex>
        <VFlex a-st width={70}>
          <Select
            menuPortalTarget={document.body}
            placeholder="월"
            isSearchable={false}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            value={day ? { value: month, label: month } : undefined}
            onChange={(item) => {
              if (item && _.isNumber(year)) {
                setDayOptions(
                  _.range(
                    1,
                    new Date(year!, item.value! - 1, 0).getDate() + 1
                  ).map((value) => ({ value, label: value }))
                );
                setMonth(item.value);
              }
            }}
            options={_.range(1, 13)
              .map((value) => value)
              .map((value) => ({ value, label: value }))}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: "8px",
              }),
            }}
          />
        </VFlex>
        <VFlex a-st width={70}>
          <Select
            menuPortalTarget={document.body}
            placeholder="일"
            isSearchable={false}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            value={day ? { value: day, label: day } : undefined}
            onChange={(item) => setDay(item?.value)}
            options={dayOptions}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: "8px",
              }),
            }}
          />
        </VFlex>
      </HFlex>
    </VFlex>
  );
}

export default DateInputRenderer;
