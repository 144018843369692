import NavBar from "../../common/NavBar/NavBar";
import Text from "../../layouts/Text";
import VFlex from "../../layouts/VFlex";
import { useCallback, useContext, useMemo } from "react";
import api from "../../common/api";
import { useState } from "react";
import { useEffect } from "react";
import { AppContext, UserRole } from "../../App";
import Button from "../../layouts/Button";
import SvgIcon, { SvgIconType } from "../../svg";
import HFlex from "../../layouts/HFlex";
import Image from "../../layouts/Image";
import { format, isValid, parseISO } from "date-fns";
import _ from "lodash";
import Divider from "../../layouts/Divider";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumberWithDash } from "../../common/util";

export type Answer = {
  user: {
    id: string;
    thumbnailImage: string;
    name: string;
    organization: string;
    organizationPhone: string;
    phone: string;
    test: string;
    testNumber: string;
    educationCareerHistory: string;
  };
  answer: string;
};

export type Inquiry = {
  id: string;
  title: string;
  form: Record<string, any>;
  question: string;
  questionnaireId: string;
  answers: Answer[];
  createdAt: Date;
  steps: any[];
};

export function getComponentNameByVar(components: any[], varName: string) {
  const component = components.find((component) => component.var === varName);
  return component?.title;
}

function AnswerView({ answer }: { answer: Answer }) {
  const [clickedShowDetail, setClickedDetail] = useState<boolean>(false);

  return (
    <VFlex p-12 g-10 bdr-8 style={{ backgroundColor: "#F8F8F8" }}>
      <HFlex g-8>
        <Image
          bdr-24
          d-24
          src={"https://cdn-icons-png.flaticon.com/512/6112/6112948.png"}
          alt="profile"
        />
        <VFlex g-4>
          <Text t-16>{answer.user.name} 변호사</Text>
          {!clickedShowDetail && (
            <Text
              t-12-pt
              style={{ zIndex: 10, cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                setClickedDetail(true);
              }}
            >
              상세정보 보기
            </Text>
          )}

          {clickedShowDetail && (
            <VFlex g-4>
              <Text t-12>
                {answer.user.organization} ({answer.user.organizationPhone})
              </Text>
              <Text t-12>{formatPhoneNumberWithDash(answer.user.phone)}</Text>
              <Text t-12>
                {answer.user.test} {answer.user.testNumber}회 (
                {answer.user.test === "사법시험"
                  ? parseInt(answer.user.testNumber) + 1964
                  : answer.user.test === "변호사시험"
                  ? parseInt(answer.user.testNumber) + 2011
                  : answer.user.test === "군법무관 임용시험"
                  ? parseInt(answer.user.testNumber) + 1987
                  : ""}
                년 취득)
              </Text>
              {answer.user.educationCareerHistory && (
                <p t-12>{answer.user.educationCareerHistory}</p>
              )}
              <Text
                t-12-pt
                style={{ zIndex: 10, cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setClickedDetail(false);
                }}
              >
                상세정보 숨기기
              </Text>
            </VFlex>
          )}
        </VFlex>
      </HFlex>
      <Text t-14>{answer.answer}</Text>
    </VFlex>
  );
}

function InquiryView({ i, inquiry }: { i: number; inquiry: Inquiry }) {
  const navigate = useNavigate();

  return (
    <VFlex
      key={i}
      g-8
      p-16
      bc-wt
      bdr-12
      style={{ zIndex: 1 }}
      onClick={() => {
        navigate(`/questionnaire?inquiry=${inquiry.id}`);
      }}
    >
      <VFlex key={i} g-10>
        <VFlex>
          <VFlex a-s j-c g-4>
            <Text t-18 f-1>
              {inquiry.title}
            </Text>
            <Text t-12-g7 l-1>
              {format(new Date(inquiry.createdAt), "yyyy-MM-dd")}
            </Text>
          </VFlex>
        </VFlex>
        <Divider />
        <Text t-16>선택내용</Text>
        {_.entries(inquiry.form)
          .map(([key, value]) => [
            getComponentNameByVar(
              _.flatten(inquiry.steps.map((s) => s.components)),
              key
            ),
            _.isObject(value)
              ? `${(value as any)["value"]}${(value as any)["unit"] ?? ""}`
              : isValid(parseISO(value))
              ? format(new Date(value), "yyyy-MM-dd")
              : value,
          ])
          .filter(([key, value]) => !_.isNil(key) && !_.isNil(value))
          .map(([key, value], j) => (
            <VFlex key={j}>
              <Text t-12-g7>{key}</Text>
              <Text t-14>{value}</Text>
            </VFlex>
          ))}
        <Divider />
        <Text t-16>질의응답</Text>
        <Text t-14>{inquiry.question}</Text>
        {inquiry.answers.length === 0 ? (
          <Button
            enabled={false}
            height={56}
            type="secondary"
            caption={"답변을 기다리는 중이에요!"}
          />
        ) : (
          inquiry.answers.map((answer, j) => (
            <AnswerView key={"answer-" + j} answer={answer} />
          ))
        )}
      </VFlex>
    </VFlex>
  );
}

function Inquiries({ inquiries }: { inquiries: Inquiry[] }) {
  return (
    <>
      {inquiries.map((inquiry, i) => (
        <InquiryView i={i} inquiry={inquiry} />
      ))}
    </>
  );
}
function Profile() {
  const { user, login, logout } = useContext(AppContext)!;
  const navigate = useNavigate();
  const [inquiries, setInquiries] = useState<Inquiry[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const inquiriesWithQuestion = useMemo(
    () => inquiries.filter((inquiry) => !!inquiry.question),
    [inquiries]
  );
  const [answeredInquiries, setAnsweredInquiries] = useState<Inquiry[]>([]);
  const loadInquiries = useCallback(async () => {
    setInquiries(await api.get("inquiry"));
  }, []);
  const loadAnsweredInquiries = useCallback(async () => {
    if (!user?.anonymous && user?.role === UserRole.LAWYER) {
      setAnsweredInquiries(await api.get("inquiry/inquiries/answered"));
    }
  }, [user]);
  useEffect(() => {
    setIsLoading(true);

    loadInquiries();

    setIsLoading(false);
  }, [loadInquiries]);
  useEffect(() => {
    setIsLoading(true);

    loadAnsweredInquiries();

    setIsLoading(false);
  }, [loadAnsweredInquiries]);

  const handleLogoutEvent = useCallback(
    (message: { data: any }) => {
      const parsedMessage = JSON.parse(message.data);
      if (parsedMessage.type === "logout_webview") {
        logout();
      }
    },
    [logout]
  );

  useEffect(() => {
    window.addEventListener("message", async (message) => {
      handleLogoutEvent(message);
    });
    document.addEventListener("message", async (message) => {
      handleLogoutEvent(message as Event & { data: any });
    });
  }, [handleLogoutEvent]);

  if (user?.anonymous) {
    return (
      <VFlex p-16-rl a-c width={"100%"} style={{ maxWidth: 430 }}>
        <HFlex as-st>
          <NavBar profile={false} />
        </HFlex>
        <SvgIcon size={100} icon={SvgIconType.AnonymousProfile} />
        <Text
          style={{ marginTop: 100, width: "100%", textAlign: "center" }}
          t-18
        >
          회원가입을 하면 올로를 통해 <br /> 보다 다양한 경험을 할 수 있어요.
        </Text>
        <HFlex as-st style={{ marginTop: 33 }}>
          <Button
            f-1
            g-4
            type={"confirm"}
            onClick={() => login()}
            caption="로그인/회원가입"
          />
        </HFlex>
      </VFlex>
    );
  }
  if (user?.role === UserRole.LAWYER) {
    return (
      <VFlex p-16-rl a-c width={"100%"} style={{ maxWidth: 430 }}>
        <HFlex as-st a-c>
          <NavBar profile={false} />
          <HFlex f-1 />
          <Button
            type="secondary"
            style={{
              width: "fit-content",
              fontSize: "14px",
              height: 40,
              padding: "0px 12px",
            }}
            caption={"프로필 수정"}
            onClick={() => {
              navigate("/profile/lawyer/edit");
            }}
          />
        </HFlex>
        <VFlex as-st width={"100%"}>
          <VFlex g-24 a-c m-12-b width={"100%"}>
            <SvgIcon size={100} icon={SvgIconType.LawyerProfile} />
            <VFlex g-4 a-c j-s>
              {user.username && <Text t-22>{user.username}</Text>}
              {user.email && <Text t-16>{user.email}</Text>}
              <Text t-16>{user.name} 변호사</Text>
              {user.organization && (
                <Text t-12>
                  {user.organization}
                  {user.organizationPhone && ` (${user.organizationPhone})`}
                </Text>
              )}

              <Text t-12>{formatPhoneNumberWithDash(user.phone)}</Text>
              {user.test && (
                <Text t-12>
                  {user.test} {user.testNumber && `${user.testNumber}회`}
                  {user.testNumber &&
                    ` (${
                      user.test === "사법시험"
                        ? parseInt(user.testNumber) + 1964
                        : user.test === "변호사시험"
                        ? parseInt(user.testNumber) + 2011
                        : user.test === "군법무관 임용시험"
                        ? parseInt(user.testNumber) + 1987
                        : ""
                    }년 취득)`}
                </Text>
              )}
              {user.educationCareerHistory && (
                <Text t-12>{user.educationCareerHistory}</Text>
              )}
              {!user?.approved &&
              (user.name ||
                user.organization ||
                user.test ||
                user.testNumber) ? (
                <Text t-12-g7>변호사 인증 중입니다.</Text>
              ) : user?.approved ? null : (
                <Text t-12-g7>변호사 인증이 필요합니다.</Text>
              )}
            </VFlex>
            <HFlex g-16>
              <Button
                type="secondary"
                caption="로그아웃"
                onClick={() => {
                  if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        message: "logout",
                      })
                    );
                    logout();
                  } else {
                    logout();
                  }
                }}
              />
              <Button
                type="secondary"
                caption="계정삭제"
                onClick={async () => {
                  if (
                    window.confirm(
                      "계정이 데이터베이스에서 삭제되며 되돌릴 수 없습니다. 그래도 하시겠습니까?"
                    )
                  ) {
                    await api.delete("/user");
                    logout();
                  }
                }}
              />
            </HFlex>
          </VFlex>
          <VFlex as-st m-32-t g-16>
            <Text t-18>나의 답변</Text>
            {isLoading ? (
              <div
                style={{ width: "100%", height: "100%" }}
                className="Loader-large"
              />
            ) : answeredInquiries.length > 0 ? (
              <Inquiries inquiries={answeredInquiries} />
            ) : (
              <HFlex bc-wt bdr-12 style={{ height: 200 }} c-c>
                <Text t-16-g7>답변할 질문이 없습니다.</Text>
              </HFlex>
            )}
          </VFlex>
        </VFlex>
      </VFlex>
    );
  }
  return (
    <VFlex p-16-brl a-c width={"100%"} style={{ maxWidth: 430 }}>
      <HFlex as-st>
        <NavBar profile={false} />
      </HFlex>
      {user && (
        <VFlex as-st ovf-s-d>
          <VFlex g-24 a-c m-12-b>
            {user.thumbnailImage ? (
              <Image
                src={user.thumbnailImage + "?type=s160"}
                alt="profile"
                style={{
                  borderRadius: "50%",
                  aspectRatio: "auto",
                  verticalAlign: "top",
                  width: 112,
                  height: 112,
                  overflowClipMargin: "content-box",
                  overflow: "clip",
                  boxShadow: "0 3px 6px 0 rgba(29,34,53,.08)",
                }}
              />
            ) : (
              <SvgIcon size={100} icon={SvgIconType.AnonymousProfile} />
            )}
            <VFlex g-4 a-c>
              {user.username && <Text t-22>{user.username}</Text>}
              {user.name && <Text t-16>{user.name}</Text>}
              {user.email && <Text t-16>{user.email}</Text>}
              {user.phone && (
                <Text t-16>{formatPhoneNumberWithDash(user.phone)}</Text>
              )}
            </VFlex>
            <HFlex g-16>
              <Button
                type="secondary"
                caption="로그아웃"
                onClick={() => {
                  if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        message: "logout",
                      })
                    );
                    logout();
                  } else {
                    logout();
                  }
                }}
              />
              <Button
                type="secondary"
                caption="계정삭제"
                onClick={async () => {
                  if (
                    window.confirm(
                      "계정이 데이터베이스에서 삭제되며 되돌릴 수 없습니다. 그래도 하시겠습니까?"
                    )
                  ) {
                    await api.delete("/user");
                    logout();
                  }
                }}
              />
            </HFlex>
          </VFlex>
          {isLoading ? (
            <div
              style={{ width: "100%", height: "100%" }}
              className="Loader-large"
            />
          ) : inquiriesWithQuestion && inquiriesWithQuestion.length > 0 ? (
            <VFlex g-16 as-st m-32-t>
              <Text t-18>나의 질문</Text>
              <Inquiries inquiries={inquiriesWithQuestion} />
            </VFlex>
          ) : (
            <VFlex g-16 as-st m-32-t>
              <Text t-18>나의 질문</Text>
              <HFlex bc-wt bdr-12 style={{ height: 200 }} c-c>
                <Text t-16-g7>올로 변호사에게 질문을 남겨보세요.</Text>
              </HFlex>
            </VFlex>
          )}
        </VFlex>
      )}
    </VFlex>
  );
}

export default Profile;
