import NavBar from "../common/NavBar/NavBar";
import { Link, useNavigate } from "react-router-dom";
import Text from "../layouts/Text";
import VFlex from "../layouts/VFlex";
import HFlex from "../layouts/HFlex";
import { useMemo, useState } from "react";
import Button from "../layouts/Button";
import api from "../common/api";
import SvgIcon, { SvgIconType } from "../svg";
import { useCookies } from "react-cookie";

function Onboarding() {
  const [cookies, , removeCookie] = useCookies();
  const navigate = useNavigate();
  const [agreed1, setAgreed1] = useState(false);
  const [agreed2, setAgreed2] = useState(false);
  const [agreed3, setAgreed3] = useState(false);
  const [agreed4, setAgreed4] = useState(false);
  const isReady = useMemo(
    () => agreed1 && agreed2 && agreed3,
    [agreed1, agreed2, agreed3]
  );
  return (
    <VFlex
      g-20
      height={"100%"}
      width={"100%"}
      style={{ maxWidth: 430 }}
      p-16-brl
    >
      <NavBar profile={false} title={"동의"} />
      <VFlex g-8 p-32-tb g-16>
        <HFlex g-8>
          <SvgIcon
            size={24}
            icon={agreed1 ? SvgIconType.CheckedBox : SvgIconType.UncheckedBox}
            onClick={() => setAgreed1(!agreed1)}
          />
          <Text t-16-g7 f-1>
            [필수] 만 14세 이상입니다.
          </Text>
        </HFlex>
        <HFlex g-8>
          <SvgIcon
            size={24}
            icon={agreed2 ? SvgIconType.CheckedBox : SvgIconType.UncheckedBox}
            onClick={() => setAgreed2(!agreed2)}
          />
          <HFlex f-1 g-8>
            <Text t-16-g7 f-1>
              [필수] 서비스 이용약관 동의
            </Text>
            <Link to="/termsofservice" target="_blank">
              <Text t-12-g7>내용보기</Text>
            </Link>
          </HFlex>
        </HFlex>
        <HFlex g-8>
          <SvgIcon
            size={24}
            icon={agreed3 ? SvgIconType.CheckedBox : SvgIconType.UncheckedBox}
            onClick={() => setAgreed3(!agreed3)}
          />
          <HFlex f-1 g-8>
            <Text t-16-g7 f-1>
              [필수] 개인정보 수집/이용 동의
            </Text>
            <Link to="/privacy_agreement" target="_blank">
              <Text t-12-g7>내용보기</Text>
            </Link>
          </HFlex>
        </HFlex>
        <HFlex g-8>
          <SvgIcon
            size={24}
            icon={agreed4 ? SvgIconType.CheckedBox : SvgIconType.UncheckedBox}
            onClick={() => setAgreed4(!agreed4)}
          />
          <HFlex f-1 g-8>
            <Text t-16-g7 f-1>
              [선택] 마케팅 수신 동의
            </Text>
            <Link to="/marketing" target="_blank">
              <Text t-12-g7>내용보기</Text>
            </Link>
          </HFlex>
        </HFlex>
      </VFlex>
      <HFlex as-st>
        <Button
          f-1
          enabled={!!isReady}
          caption="계속하기"
          type={"confirm"}
          onClick={async () => {
            await api.post(`/auth/onboarding?agreedMarketing=${agreed4}`, {});

            if (cookies["redirect"]) {
              let redirectUrl = cookies["redirect"];
              removeCookie("redirect");
              navigate(redirectUrl);
            } else {
              navigate("/");
            }
          }}
        />
      </HFlex>
    </VFlex>
  );
}

export default Onboarding;
