import { ReactElement } from "react";
import Button from "../../../../layouts/Button";
import HFlex from "../../../../layouts/HFlex";

interface StickButtonParams {
  targetStep: string;
  targetPath: string;
}

export interface StickyButtonComponent {
  value: string;
  params: StickButtonParams;
}

type ComponentProps = {
  component: StickyButtonComponent;
  completed: boolean;
  onPressNext: (targetStep: string, targetPath: string) => void;
};

function StickyButtonRenderer({
  component,
  completed,
  onPressNext,
}: ComponentProps): ReactElement {
  return (
    <HFlex
      c-c
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
      }}
    >
      <HFlex c-c style={{ maxWidth: 430, width: "100%" }} p-8 bd-t bc-g9>
        <Button
          height={40}
          f-1
          type="confirm"
          enabled={completed}
          caption={component.value}
          onClick={() =>
            onPressNext(
              component.params.targetStep,
              component.params.targetPath
            )
          }
        />
      </HFlex>
    </HFlex>
  );
}

export default StickyButtonRenderer;
