import { Link } from "react-router-dom";
import Text from "../layouts/Text";
import VFlex from "../layouts/VFlex";
import SvgIcon, { SvgIconType } from "../svg";
import HFlex from "../layouts/HFlex";

function Footer() {
  return (
    <VFlex bc-g2 p-32-tb p-16-rl g-16>
      <SvgIcon icon={SvgIconType.LogoText} width={118} height={23} />
      <VFlex g-4 t-12-g7>
        <HFlex g-8>
          <Link to={`/termsofservice`}>
            <Text>이용약관</Text>
          </Link>
          <Link to={`/privacy`}>
            <Text>
              <strong>개인정보처리방침</strong>
            </Text>
          </Link>
          <Text>
            <a
              href="mailto:mslawcompany@gmail.com?Subject=올로에 관하여 문의드립니다."
              target="_top"
            >
              고객센터
            </a>
          </Text>
        </HFlex>
        <Text>
          엠에스(ms)컴퍼니 서울특별시 강남구 도산대로27길 25, 101호(신사동)
          사업자등록번호: 795-09-02171 대표자명: 김민석 대표번호: 070-8027-2280
          이메일: mslawcompany@gmail.com
        </Text>
      </VFlex>
    </VFlex>
  );
}

export default Footer;
