import _ from "lodash";
import { HTMLAttributes } from "react";
import {
  ColorProps,
  FontProps,
  LineProps,
  propsToClasses,
  restOfProps,
} from "./index_generated";
import "./layout.scss";

type TextProps = {
  classes?: string[];
  "t-m"?: boolean;
} & HTMLAttributes<HTMLSpanElement> &
  ColorProps &
  FontProps &
  LineProps;

const Text = ({
  classes = [],
  children,
  color = "pt",
  style,
  ...props
}: TextProps) => {
  return (
    <span
      className={_.compact([...propsToClasses(props), ...classes]).join(" ")}
      {...restOfProps(props)}
      style={{
        ...style,
      }}
    >
      {children}
    </span>
  );
};

export default Text;
