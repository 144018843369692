/* eslint-disable @typescript-eslint/no-unused-vars */
import NavBar from "../../common/NavBar/NavBar";
import VFlex from "../../layouts/VFlex";
import { useContext, useEffect, useMemo } from "react";
import api from "../../common/api";
import { useState } from "react";
import { AppContext, UserRole } from "../../App";
import Button from "../../layouts/Button";
import HFlex from "../../layouts/HFlex";
import Image from "../../layouts/Image";
import { useNavigate } from "react-router-dom";
import TextInput from "../../layouts/TextInput";
import { formatPhoneNumber } from "../../common/util";
import Text from "../../layouts/Text";
import TextArea from "../../layouts/TextArea";
import SvgIcon, { SvgIconType } from "../../svg";

export const EditLawyerProfile: React.FC<{}> = () => {
  const { setUser, user } = useContext(AppContext)!;
  const navigate = useNavigate();
  const [name, setName] = useState((!user?.anonymous && user?.name) || "");
  const [organization, setOrganization] = useState(
    (!user?.anonymous && user?.organization) || ""
  );
  const [organizationPhone, setOrganizationPhone] = useState(
    (!user?.anonymous && user?.organizationPhone) || ""
  );
  const [email, setEmail] = useState((!user?.anonymous && user?.email) || "");
  const [educationCareerHistory, setEducationCareerHistory] = useState(
    (!user?.anonymous && user?.educationCareerHistory) || ""
  );

  const [isValidName, setIsValidName] = useState(true);
  const [isValidOrganization, setIsValidOrganization] = useState(true);
  const [isValidOrganizationPhone, setIsValidOrganizationPhone] =
    useState(true);

  const [isValidEmail, setIsValidEmail] = useState(true);

  useEffect(() => {
    if (user?.anonymous || user?.role !== UserRole.LAWYER) {
      navigate("/login");
    }
  });

  const anythingChanged = useMemo(
    () =>
      !user?.anonymous &&
      (name !== user?.name ||
        organization !== user?.organization ||
        organizationPhone !== user?.organizationPhone ||
        email !== user?.email ||
        educationCareerHistory !== user?.educationCareerHistory),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, organization, organizationPhone, email, educationCareerHistory]
  );

  if (!user?.anonymous) {
    return (
      <VFlex p-16-rl a-c width={"100%"} style={{ maxWidth: 430 }}>
        <VFlex width={"100%"}>
          <HFlex as-st a-c>
            <NavBar profile={false} />
            <HFlex f-1 />
            <Button
              type="secondary"
              p-8-rl
              style={{
                width: "fit-content",
                fontSize: "14px",
                height: 40,
                padding: "0px 12px",
              }}
              caption={"수정 완료"}
              enabled={anythingChanged}
              onClick={async () => {
                if (!name) {
                  setIsValidName(false);
                  return;
                }
                if (!organization) {
                  setIsValidOrganization(false);
                  return;
                }
                if (
                  !organizationPhone ||
                  !organizationPhone.startsWith("0") ||
                  (organizationPhone.startsWith("010") &&
                    organizationPhone.length < 13) ||
                  (organizationPhone.startsWith("02") &&
                    organizationPhone.length < 11) ||
                  (organizationPhone.startsWith("0") &&
                    !organizationPhone.startsWith("010") &&
                    !organizationPhone.startsWith("02") &&
                    organizationPhone.length < 12)
                ) {
                  setIsValidOrganizationPhone(false);
                  return;
                }

                const res = await api.put("/user/lawyer", {
                  name,
                  organization,
                  organizationPhone,
                  email,
                  educationCareerHistory,
                });
                console.log(res);
                setUser({ username: res.local.username, ...res });
                navigate(-1);
              }}
            />
          </HFlex>
          <VFlex as-st ovf-s-d width={"100%"}>
            <VFlex g-24 a-c m-12-b width={"100%"}>
              <SvgIcon size={100} icon={SvgIconType.LawyerProfile} />
              <VFlex g-8 width="100%">
                <VFlex
                  bc-wt
                  bdr-12
                  p-16
                  g-4
                  style={{ width: "100%", height: "fit-content" }}
                >
                  <TextInput
                    caption="이름"
                    placeholder={user?.name}
                    value={name}
                    onChangeValue={(val) => {
                      setName(val);
                    }}
                    error={isValidName === false}
                  />
                  {isValidName === false && (
                    <Text t-12-bb>이름을 입력해주세요.</Text>
                  )}
                </VFlex>
                <VFlex
                  bc-wt
                  bdr-12
                  p-16
                  g-4
                  style={{ width: "100%", height: "fit-content" }}
                >
                  <TextInput
                    caption="소속"
                    placeholder={user?.organization}
                    value={organization}
                    onChangeValue={(val) => {
                      setOrganization(val);
                    }}
                    error={isValidOrganization === false}
                  />
                  {isValidOrganization === false && (
                    <Text t-12-bb>소속된 회사를 입력해주세요.</Text>
                  )}
                </VFlex>
                <VFlex
                  bc-wt
                  bdr-12
                  p-16
                  g-4
                  style={{ width: "100%", height: "fit-content" }}
                >
                  <TextInput
                    caption="소속 전화번호"
                    placeholder={"소속 전화번호"}
                    value={organizationPhone}
                    inputMode="numeric"
                    onKeyDown={(e: any) => {
                      if (!(e.key === "Backspace" || /[0-9]/.test(e.key))) {
                        e.preventDefault();
                      }
                    }}
                    onChangeValue={(value) =>
                      setOrganizationPhone(
                        (phone) => formatPhoneNumber(phone, value)!
                      )
                    }
                    error={isValidOrganizationPhone === false}
                  />
                  {isValidOrganizationPhone === false && (
                    <Text t-12-bb>올바른 소속 전화번호를 입력해주세요.</Text>
                  )}
                </VFlex>
                <VFlex
                  bc-wt
                  bdr-12
                  p-16
                  g-4
                  style={{ width: "100%", height: "fit-content" }}
                >
                  <TextInput
                    caption="이메일"
                    placeholder={"이메일"}
                    value={email}
                    onChangeValue={(value) => setEmail(value)}
                    error={!isValidEmail}
                  />
                  {!isValidEmail && <Text t-12-bb>A가 틀렸을경우 메시지.</Text>}
                </VFlex>
                <VFlex
                  bc-wt
                  bdr-12
                  p-16
                  g-6
                  style={{ width: "100%", height: "fit-content" }}
                >
                  <Text t-16>학력 및 경력 (선택사항)</Text>
                  <TextArea
                    bdr-8
                    placeholder={
                      "**대학교 *과 졸업 / **대학교 법학전문대학원 졸업 / 민사전문변호사"
                    }
                    value={educationCareerHistory}
                    onChangeValue={(value) => setEducationCareerHistory(value)}
                    style={{ height: 100, overflowY: "scroll" }}
                  />
                </VFlex>
              </VFlex>
            </VFlex>
          </VFlex>
        </VFlex>
      </VFlex>
    );
  } else {
    return <></>;
  }
};
