import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import VFlex from "../../../layouts/VFlex";
import SvgIcon, { SvgIconType } from "../../../svg";

export type ComponentHeaderProps = {
  icon?: SvgIconType;
  completed?: boolean;
  title?: string;
  subtitle?: string;
  logo?: boolean;
  primaryColor?: boolean;
  titleFontSize?: number;
};

function ComponentHeader({
  icon,
  completed,
  title,
  subtitle,
  logo,
  primaryColor,
}: ComponentHeaderProps) {
  return (
    <HFlex a-c g-12 classes={["ComponentHeader"]}>
      {icon && (
        <SvgIcon icon={completed ? SvgIconType.IconCheck : icon} size={32} />
      )}
      {logo && <SvgIcon icon={SvgIconType.LogoText} />}
      <VFlex>
        <Text
          t-16-m
          style={{
            paddingTop: 2,
            color: "#3A3A3A",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "24px",
          }}
        >
          {title}
        </Text>
        {subtitle && (
          <Text t-12 l-1>
            {subtitle}
          </Text>
        )}
      </VFlex>
    </HFlex>
  );
}

export default ComponentHeader;
