import NavBar from "../../common/NavBar/NavBar";
import { useNavigate } from "react-router-dom";
import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Button from "../../layouts/Button";
import api from "../../common/api";
import TextInput from "../../layouts/TextInput";
import { useContext, useState } from "react";
import { formatPhoneNumber } from "../../common/util";
import Select from "react-select";
import Text from "../../layouts/Text";
import _ from "lodash";
import { AppContext } from "../../App";

function LawyerSignupL3() {
  const { setUser } = useContext(AppContext)!;
  const navigate = useNavigate();
  const [name, setName] = useState<string>();
  const [organizationPhone, setOrganizationPhone] = useState<string>();
  const [organization, setOrganization] = useState<string>();
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [registrationId, setRegistrationId] = useState("");
  const [test, setTest] = useState("");
  const [testNumber, setTestNumber] = useState("");
  return (
    <VFlex g-8 p-16-brl width={"100%"} style={{ maxWidth: 430 }}>
      <NavBar
        profile={false}
        title={"인증정보"}
        onBack={() => {
          navigate("/");
        }}
      />
      <VFlex g-32 width="100%" f-1>
        <VFlex g-16>
          <TextInput
            caption="이름"
            placeholder={"이름"}
            value={name}
            onChangeValue={async (value) => {
              setName(value);
            }}
          />
          <TextInput
            caption="소속"
            placeholder={"소속"}
            value={organization}
            onChangeValue={async (value) => {
              setOrganization(value);
            }}
          />
          <TextInput
            caption="소속 전화번호"
            placeholder={"소속 전화번호"}
            value={organizationPhone}
            inputMode="numeric"
            onKeyDown={(e: any) => {
              if (!(e.key === "Backspace" || /[0-9]/.test(e.key))) {
                e.preventDefault();
              }
            }}
            onChangeValue={(value) =>
              setOrganizationPhone((phone) => formatPhoneNumber(phone, value))
            }
          />
          <TextInput
            prefix={"제"}
            postfix={"호"}
            caption={"대한변호사협회 신분증 등록번호"}
            placeholder={"등록번호"}
            value={registrationNumber}
            inputMode="numeric"
            onChangeValue={(value) => setRegistrationNumber(value)}
          />
          <TextInput
            prefix={"제"}
            postfix={"호"}
            caption={"대한변호사협회 신분증 발급번호"}
            placeholder={"발급번호"}
            value={registrationId}
            inputMode="numeric"
            onChangeValue={(value) => setRegistrationId(value)}
          />
          <VFlex f-1 g-6 style={{ pointerEvents: "auto" }}>
            <HFlex g-8>
              <Text t-16-g0>출신시험</Text>
            </HFlex>
            <Select
              isSearchable={false}
              menuPortalTarget={document.body}
              placeholder="출신시험"
              value={{ value: test, label: test }}
              onChange={(item) => setTest(item?.value ?? "")}
              options={["사법시험", "변호사시험", "군법무관 임용시험"].map(
                (value) => ({ value, label: value })
              )}
            />
          </VFlex>
          {!!test && (
            <VFlex f-1 g-6 style={{ pointerEvents: "auto" }}>
              <HFlex g-8>
                <Text t-16-g0>시험횟수</Text>
              </HFlex>
              <Select
                isSearchable={false}
                menuPortalTarget={document.body}
                placeholder="시험횟수"
                value={{ value: testNumber, label: testNumber }}
                onChange={(item) => setTestNumber(item?.value ?? "")}
                options={
                  test === "사법시험"
                    ? _.range(1964, 2023)
                        .map((value) => value - 1964 + 1)
                        .map((value) => ({
                          value: value.toString(),
                          label: value.toString(),
                        }))
                        .reverse()
                    : test === "변호사시험"
                    ? _.range(2012, new Date().getFullYear())
                        .map((value) => value - 2011 + 1)
                        .map((value) => ({
                          value: value.toString(),
                          label: value.toString(),
                        }))
                        .reverse()
                    : test === "군법무관 임용시험"
                    ? _.range(2004, 2023)
                        .map((value) => value - 2004 + 1)
                        .map((value) => ({
                          value: value.toString(),
                          label: value.toString(),
                        }))
                        .reverse()
                    : []
                }
              />
            </VFlex>
          )}
        </VFlex>
        <VFlex height={32} />
        <VFlex a-c>
          <Text t-14>가입신청을 하시면 변호사 인증절차를 거쳐</Text>
          <Text t-14>변호사 회원자격이 부여됩니다.</Text>
        </VFlex>
        <VFlex g-16>
          <HFlex as-st>
            <Button
              f-1
              caption="저장"
              type={"confirm"}
              enabled={
                !!name &&
                !!organization &&
                !!organizationPhone &&
                !!registrationNumber &&
                !!registrationId &&
                !!test &&
                !!testNumber
              }
              onClick={async () => {
                await api.put("/user/lawyer", {
                  name,
                  organization,
                  organizationPhone,
                  registrationNumber,
                  registrationId,
                  test,
                  testNumber,
                });
                const user = await api.get("/user");
                setUser(user);
                navigate("/");
              }}
            />
          </HFlex>
        </VFlex>
      </VFlex>
    </VFlex>
  );
}

export default LawyerSignupL3;
