import { ReactElement } from "react";
import { ComponentProps } from "..";
import Text from "../../../../layouts/Text";
import VFlex from "../../../../layouts/VFlex";
import ComponentHeader, { ComponentHeaderProps } from "../ComponentHeader";

export type ResultComponentProps = {
  value: string;
} & ComponentHeaderProps;

function ResultRenderer({
  component,
  value,
  setValue,
}: ComponentProps<string, ResultComponentProps>): ReactElement {
  return (
    <VFlex bdr-12 bc-g9 p-16 g-8 width="100%">
      <ComponentHeader {...component} logo={true} />
      <VFlex m-8-t g-32>
        <Text t-16 style={{ whiteSpace: "pre-line" }}>
          {component.value}
        </Text>
        <Text t-10-m-g7 style={{ whiteSpace: "pre-line" }}>
          {
            "*주의\n올로는 일상생활 속 법률관계에 관한 길잡이가 되어드리고자 합니다.\n선택지 외에 다른 중요한 사실관계가 존재한다면, 위 답변과 다른 결과가 나올 수 있는 점 유의해주세요!\n선택지와 사실관계가 일치하는지 헷갈리시거나, 더 자세한 내용을 알고 싶으신 분은 올로 변호사에게 질문하여 보다 정확한 답변을 얻으세요."
          }
        </Text>
      </VFlex>
    </VFlex>
  );
}

export default ResultRenderer;
