import NavBar from "../../common/NavBar/NavBar";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";
import VFlex from "../../layouts/VFlex";

function Privacy() {
  return (
    <VFlex p-16-brl a-c width={"100%"} style={{ maxWidth: 430 }}>
      <HFlex as-st>
        <NavBar profile={false} />
      </HFlex>
      <Text t-22-m>개인정보처리방침</Text>
      <Text><br></br><br></br>엠에스(ms)컴퍼니(이하 "회사")는 개인정보보호법, 통신비밀보호법, 전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.<br></br>
본 개인정보처리방침은 회사가 제공하는 올로(ALLAW) 서비스(이하 "서비스") 이용에 적용되며 목차는 다음와 같습니다.<br></br>
<br></br>
1. 개인정보 수집항목 및 수집방법<br></br>
2. 수집한 개인정보의 이용목적<br></br>
3. 개인정보의 제공<br></br>
4. 개인정보의 처리위탁<br></br>
5. 개인정보의 보유 및 이용기간<br></br>
6. 개인정보의 파기절차 및 방법<br></br>
7. 이용자 또는 그 법정대리인의 권리 및 그 행사방법<br></br>
8. 개인정보 자동수집장치의 설치ㆍ운영 및 거부에 관한 사항<br></br>
9. 개인정보의 안전성 보호대책<br></br>
10. 개인정보보호책임자<br></br>
11. 개인정보처리방침 고지의무<br></br>
<br></br>
<br></br>
<br></br>
<strong>1. 개인정보 수집항목 및 수집방법</strong><br></br>
<strong>가. 개인정보 수집항목</strong><br></br>
- 회원가입 과정에서 다음와 같은 개인정보를 수집합니다.<br></br>
[일반회원] 이메일 주소, 아이디, 비밀번호, 휴대전화번호<br></br>
[변호사회원] 이메일 주소, 아이디, 비밀번호, 휴대전화번호, 이름, 소속사무소명, 소속사무소 전화번호, 출신시험, 변호사등록증 등록번호, 변호사등록증 발급번호, 프로필사진<br></br>
<br></br>
- 온라인상담 서비스 과정에서 다음과 같은 개인정보를 수집합니다.<br></br>
[일반회원] 상담 요청 시 작성한 닉네임, 상담요청 내역<br></br>
<br></br>
- 나홀로소송 양식제공 서비스 과정에서 다음과 같은 개인정보를 수집합니다.<br></br>
[일반회원] 구매 및 결제 내역, 영수증(카드종류, 카드정보-일부표시)<br></br>
<br></br>
- 고객센터 이용 과정에서 다음과 같은 개인정보를 수집합니다.<br></br>
[일반회원] 이메일주소, 발신전화번호, 문의내용<br></br>
<br></br>
- 서비스 이용 과정에서 다음과 같은 개인정보를 자동으로 수집합니다.<br></br>
PC웹, 모바일웹/앱 이용과정에서의 단말기정보(OS, 화면사이즈, 디바이스 아이디, 단말기 기종, 단말기 모델명), IP주소, 쿠키, 방문일시 부정이용기록, 서비스 이용 기록, 앱 설치 및 사용이력 등의 정보가 자동으로 생성되어 수집될 수 있습니다.<br></br>
<br></br>
<strong>나. 개인정보 수집방법</strong><br></br>
서비스 이용과정(회원가입, 클릭검색 서비스, 나홀로소송 양식제공 서비스, 온라인상담 서비스의 질문 및 답변 작성, 프로필 작성 등)을 통하여 개인정보를 수집합니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>2. 수집한 개인정보의 이용목적</strong><br></br>
- 회원가입, 관련 제반 서비스(모바일 웹/앱 포함)의 회원관리, 원활한 고객상담, 이용자와 약속한 서비스 제공, 구매 및 요금결제, 이용내역 제공, 상품의 제공, 서비스 개발·제공 및 향상 등을 위하여 개인정보를 이용합니다.<br></br>
- 회원 가입 의사의 확인, 이용자 식별, 계정정지 또는 회원탈퇴 의사의 확인, 문의사항 또는 불만처리 등 회원관리를 위하여 개인정보를 이용합니다.<br></br>
- 법령 및 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위 및 비인가 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 고지사항 전달, 분쟁 조정을 위한 기록보존 등 이용자 보호 및 서비스 이용환경을 확인하고 안정적인 운영을 위하여 개인정보를 이용합니다.<br></br>
- 인구통계학적 특성에 따른 서비스 제공, 접속 빈도 분석, 기능개선, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 기반하여 이용자의 상품 구매 및 서비스 이용 성향, 관심, 이용기록 분석 등을 반영한 신규 서비스 제공(개인 맞춤형 상품 추천 서비스 등 포함)등에 개인정보를 이용합니다.<br></br>
- 관련 법령에 따라 통계작성, 과학적 연구, 공익적 기록보존 등을 위하여 개인정보를 가명처리 할 수 있습니다.<br></br>
- 이벤트 정보, 광고성 정보 제공 등 이벤트 및 프로모션의 목적 등에 개인정보를 이용합니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>3. 개인정보의 제공</strong><br></br>
회사는 이용자들의 개인정보를 "2. 수집한 개인정보의 이용목적"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br></br>
- 이용자가 사전에 동의한 경우<br></br>
- 서비스 제공에 따른 요금정산을 위해 필요한 경우<br></br>
- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br></br>
<br></br>
<br></br>
<br></br>
<strong>4. 개인정보의 처리위탁</strong><br></br>
회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 단 예외적으로 회사는 원활한 개인정보 업무 처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.<br></br>
<br></br>
<strong>가. 국내처리 위탁 현황</strong><br></br>
수탁업체: 카카오<br></br>
위탁업무 내용: 간편로그인<br></br>
<br></br>
수탁업체: 카카오페이<br></br>
위탁업무 내용: 간편결제<br></br>
<br></br>
수탁업체: 네이버<br></br>
위탁업무 내용: 간편로그인<br></br>
<br></br>
수탁업체: 네이버파이낸셜<br></br>
위탁업무 내용: 간편결제<br></br>
<br></br>
<strong>나. 국외처리 위탁 현황</strong><br></br>
수탁업체: MongoDB, Inc.<br></br>
수탁업체의 소재국가: 미국<br></br>
위탁 일시 및 방법: 서비스 이용 시점에 네트워크를 통한 전송 및 서비스 제공 과정 중 정보통신망을 통해 수시로 이전<br></br>
수탁업체의 연락처 : support@mongodb.com<br></br>
위탁하는 개인정보 항목 : 이메일주소, 휴대전화번호, 유저 식별자, 이름, 변호사회원의 소속사무소, 소속사무소 연락처, 출신시험, 방문일시, 서비스 이용기록, 기기정보 등<br></br>
위탁업무 내용 : 이용자 데이터 보관<br></br>
개인정보의 보유 및 이용 기간 : 본 개인정보 처리방침에 규정된 보관기간과 일치함<br></br>
<br></br>
수탁업체: Microsoft Azure, Idc.<br></br>
수탁업체의 소재국가: 미국<br></br>
위탁 일시 및 방법: 서비스 이용 시점에 네트워크를 통한 전송 및 서비스 제공 과정 중 정보통신망을 통해 수시로 이전<br></br>
수탁업체의 연락처 : https://support.microsoft.com<br></br>
위탁하는 개인정보 항목 : 이메일주소, 휴대전화번호, 유저 식별자, 이름, 변호사회원의 소속사무소, 소속사무소 연락처, 출신시험, 방문일시, 서비스 이용기록, 기기정보 등<br></br>
위탁업무 내용 : 이용자 데이터 처리<br></br>
개인정보의 보유 및 이용 기간 : 본 개인정보 처리방침에 규정된 보관기간과 일치함<br></br>
<br></br>
수탁업체: Twilio, Inc.<br></br>
수탁업체의 소재국가: 미국<br></br>
위탁 일시 및 방법: 서비스 이용 시점에 네트워크를 통한 전송 및 서비스 제공 과정 중 정보통신망을 통해 수시로 이전<br></br>
수탁업체의 연락처 : https://support.twilio.com<br></br>
위탁하는 개인정보 항목 : 휴대전화번호<br></br>
위탁업무 내용 : SMS/LMS 발송<br></br>
개인정보의 보유 및 이용 기간 : 본 개인정보 처리방침에 규정된 보관기간과 일치함<br></br>
<br></br>
수탁업체: Amplitude, Inc.<br></br>
수탁업체의 소재국가: 미국<br></br>
위탁 일시 및 방법: 서비스 이용 시점에 네트워크를 통한 전송 및 서비스 제공 과정 중 정보통신망을 통해 수시로 이전<br></br>
수탁업체의 연락처 : privacy@amplitude.com<br></br>
위탁하는 개인정보 항목 : 방문일시, 서비스 이용기록, 기기정보 등<br></br>
위탁업무 내용 : 이용자 서비스 이용 현황 데이터 분석<br></br>
개인정보의 보유 및 이용 기간 : 본 개인정보 처리방침에 규정된 보관기간과 일치함<br></br>
<br></br>
<br></br>
<br></br>
<strong>5. 개인정보의 보유 및 이용기간</strong><br></br>
<strong>가. 회사의 개인정보 보유 및 이용기간은 다음과 같습니다.</strong><br></br>
보유항목: 제2조 개인정보 수집항목과 동일<br></br>
보유근거: 회원의 동의(다만, 가명정보는 개인정보보호법의 관련 규정)<br></br>
보유기간: 회원자격 유지 기간<br></br>
<br></br>
<strong>나. 다만, 회원탈퇴 후에도 하기 정보는 아래의 이유로 명시한 기간 동안 보존합니다.</strong><br></br>
- 서비스 이용에 관한 로그기록<br></br>
보존근거: 통신비밀보호법<br></br>
보존기간: 3개월 이상<br></br>
<br></br>
- 소비자의 불만 또는 분쟁처리에 관한 기록<br></br>
보존근거: 전자상거래 등에서의 소비자보호에 관한 법률<br></br>
보존기간: 3년<br></br>
<br></br>
- 계약 또는 청약철회 등에 관한 기록<br></br>
보존근거: 전자상거래 등에서의 소비자보호에 관한 법률<br></br>
보존기간: 5년<br></br>
<br></br>
- 대금결제 및 재화 등의 공급에 관한 기록<br></br>
보존근거: 전자상거래 등에서의 소비자보호에 관한 법률<br></br>
보존기간: 5년<br></br>
<br></br>
- 건당 거래금액이 1만원을 초과하는 전자금융거래에 관한 기록<br></br>
보존근거: 전자금융거래법<br></br>
보존기간: 5년<br></br>
<br></br>
- 건당 거래금액이 1만원 이하인 전자금융거래에 관한 기록<br></br>
보존근거: 전자금융거래법<br></br>
보존기간: 1년<br></br>
<br></br>
- 서비스 게시물 이용권 취득과 관련해 필요한 항목(서비스 게시물에 기재된 정보)<br></br>
보존근거: 회원의 동의(회원 간 문의 내용 보존 및 회사의 이용권 취득에 따른 이용)<br></br>
보존기간: 해당 게시물에 대해 회사가 취득한 이용권의 이용기간 내<br></br>
<br></br>
- 가명처리된 회원의 이용기록<br></br>
보존근거: 개인정보보호법<br></br>
보존기간: 법령상 허용된 이용 목적 달성시까지<br></br>
<br></br>
<br></br>
<br></br>
<strong>6. 개인정보의 파기절차 및 방법</strong><br></br>
회사는 회원의 개인정보 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 그 불필요하게 된 날로부터 5일 이내 개인정보를 파기합니다. 단, 관계법령에 의하여 일정기간 보존하여야할 개인정보는 일정기간 보존 후에 지체 없이 파기됩니다.<br></br>
또한, 회사는 ‘개인정보 유효기간제’에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리하여 저장 또는 보관하며, 분리 보관된 개인정보는 4년간 보관 후 지체 없이 파기합니다.(단, 변호사로 가입한 유저는 1년 내 변호사 회원으로 승인받지 않을 경우 회원가입 시 입력한 정보가 모두 지체없이 파기됩니다.) 대상자에게는 분리 보관 처리일을 기준으로 하여 최소 30일 이전에 이메일 주소를 통해 안내를 합니다. 단, 통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률 등의 관계법령의 규정에 의하여 보존할 필요가 있는 경우 규정된 기간 동안 회원의 개인정보를 보관합니다.<br></br>
분리보관된 개인정보는 다음의 경우를 제외하고 다른 용도로 사용되지 않습니다.<br></br>
- 분리보관된 데이터의 파기<br></br>
- 탈퇴회원의 문의사항 처리<br></br>
- 법령에 근거한 정보제공이 필요한 경우<br></br>
단, 개인의 식별정보가 제거되고 가명/익명화 처리를 마친 게시물은 파기 되지 않습니다.<br></br>
<br></br>
파기절차 및 방법은 다음과 같습니다.<br></br>
<br></br>
<strong>가. 파기절차</strong><br></br>
회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기합니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보유하는 이외의 다른 목적으로 이용되지 않습니다.<br></br>
<br></br>
<strong>나. 파기방법</strong><br></br>
종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br></br>
전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>7. 이용자 또는 그 법정대리인의 권리 및 그 행사방법</strong><br></br>
- 이용자 또는 그 법정대리인은 언제든지 회사가 보유하고 있는 이용자의 개인정보, 회사가 이용자의 개인정보를 이용하거나 제3자에게 제공한 내역, 회사에게 개인정보수집ㆍ이용ㆍ제공 등의 동의를 한 내역에 관하여 개인정보 열람·정정을 요구할 수 있습니다.<br></br>
- 이용자는 회사가 운영하는 웹사이트에서 직접 자신의 정보를 열람·정정을 할 수 있으며, 별도로 개인정보보호책임자에게 서면, 전화, 전자우편 등을 통하여 개인정보의 열람, 정정을 요청할 수 있습니다.<br></br>
- 이용자 또는 그 법정대리인은 언제든지 자신의 개인정보처리의 정지를 요구할 수 있습니다.<br></br>
- 이용자 또는 그 법정대리인은 언제든지 회원가입 시 개인정보의 수집, 이용, 제공 등에 대해 동의하신 의사표시를 철회(회원탈퇴)할 수 있습니다. 회원탈퇴는 회사 웹사이트의 [마이페이지]-[회원정보]-[회원탈퇴] 메뉴에서 본인 확인 절차를 거친 후 직접하거나, 별도로 개인정보보호책임자에게 서면, 전화 또는 전자우편 등을 통하여 할 수 있습니다. 단, 동의철회(회원탈퇴)가 있더라도 관계법령에 따라 최소한의 정보가 보관될 수 있습니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>8. 개인정보 자동수집장치의 설치·운영 및 거부에 관한 사항</strong><br></br>
회사는 이용자의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 회사의 서버가 회사의 웹사이트를 이용하는 이용자의 컴퓨터에게 보내는 인터넷 이용에 관한 정보를 포함한 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다.<br></br>
<br></br>
<strong>가. 쿠키의 사용 목적</strong><br></br>
회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공<br></br>
<br></br>
<strong>나. 쿠키 설정 거부 방법</strong><br></br>
이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br></br>
단, 쿠키 설치를 거부하였을 경우 웹 이용에 불편함이 초래될 수 있으며, 로그인이 필요한 일부 서비스의 이용에 어려움이 있을 수 있습니다.<br></br>
<br></br>
<strong>다. 설정방법</strong><br></br>
- Internet Explorer의 경우: 웹 브라우저 상단의 도구 - 인터넷 옵션 - 개인정보<br></br>
- Chrome의 경우: 웹 브라우저 우측의 설정 메뉴 - 화면 하단의 고급 설정 표시 - 개인정보의 콘텐츠 설정 버튼 - 쿠키<br></br>
<br></br>
<br></br>
<br></br>
<strong>9. 개인정보의 안전성 보호대책</strong><br></br>
회사는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안정성 확보를 위하여 다음과 같은 기술적, 관리적 대책을 강구하고 있습니다.<br></br>
<br></br>
<strong>가. 개인정보 암호화</strong><br></br>
이용자의 개인정보는 비밀번호에 의해 보호되며, 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 통해 보호하고 있습니다.<br></br>
<br></br>
<strong>나. 해킹 등에 대비한 기술적 대책</strong><br></br>
회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터의 개인정보에 대한 접근통제 기능을 실행하고 있으며, 백신 프로그램을 주기적으로 업데이트하여 개인정보가 침해되는 것을 방지하고 있습니다.<br></br>
<br></br>
<strong>다. 개인정보 취급 직원의 교육</strong><br></br>
개인정보관련 취급 직원은 담당자에 한정시켜 최소화 하고 새로운 보안기술의 습득 및 개인정보보호 의무에 관해 정기적인 교육을 실시하며 별도의 비밀번호를 부여하여 접근 권한을 관리하는 등 관리적 대책을 시행하고 있습니다.<br></br>
<br></br>
<strong>라. 개인 아이디와 비밀번호 관리</strong><br></br>
이용자가 사용하는 아이디와 비밀번호는 원칙적으로 이용자만이 사용하도록 되어 있습니다. 회사는 이용자의 개인적인 부주의로 아이디, 비밀번호 등 개인정보가 유출되어 발생한 문제와 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 비밀번호에 대한 보안 의식을 가지고 비밀번호를 자주 변경하며 공용PC에서의 로그인 시 개인정보가 유출되지 않도록 각별한 주의를 기울여 주시기 바랍니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>10. 개인정보보호책임자</strong><br></br>
회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보보호책임자를 지정하고 있습니다. 이용자는 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로 신고할 수 있습니다.<br></br>
<br></br>
개인정보보호책임자: 김민석<br></br>
이메일: mslawcompany@gmail.com<br></br>
<br></br>
이용자는 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의할 수 있습니다.<br></br>
- 개인정보 침해신고센터 : (국번없이)118 (privacy.kisa.or.kr)<br></br>
- 개인정보 분쟁조정위원회 : 1833-6972 (www.kopico.go.kr)<br></br>
- 대검찰청 사이버수사과 : (국번없이)1301, cid@spo.go.kr (www.spo.go.kr)<br></br>
- 경찰청 사이버범죄 신고시스템 : (국번없이)182 (https://ecrm.cyber.go.kr)<br></br>
<br></br>
<br></br>
<br></br>
<strong>11. 개인정보처리방침 고지의무</strong><br></br>
회사는 개인정보처리방침을 개정하는 경우 최소 7일 전에 홈페이지의 공지사항을 통하여 고지합니다.<br></br>
<br></br>
<br></br>
<br></br>
본 개인정보처리방침에서 규정되지 않은 사항은 관계법령의 범위 내에서 회사 이용약관을 우선적으로 적용합니다.<br></br>
<br></br>
개인정보처리방침 공고일자: 2023년 10월 10일<br></br>
개인정보처리방침 시행일자: 2023년 10월 17일<br></br>
<br></br>
변경 이력<br></br>
2023년 10월 10일 제정</Text>
    </VFlex>
  );
}

export default Privacy;
