import bluebird from "bluebird";
import { useEffect, useRef, useState } from "react";
import api from "../../common/api";
import Text from "../../layouts/Text";
import Button from "../../layouts/Button";
import { useCallback } from "react";

function Editor({
  questionnaireId,
  setQuestionnaire,
}: {
  questionnaireId: string;
  setQuestionnaire: (data: any) => void;
}) {
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [json, setJson] = useState<string | undefined>();
  const references = useRef<Record<string, any>>({});
  useEffect(() => {
    (async () => {
      if (json) {
        try {
          const data = JSON.parse(json);
          const steps: any[] = [];
          await bluebird.map(data.steps, async (step: any) => {
            if (step.reference) {
              if (!references.current[step.reference]) {
                const q = await api.get(`/questionnaire/${step.reference}`);
                references.current[step.reference] = q?.steps ?? [];
              }
              let stepsJson = JSON.stringify(
                references.current[step.reference]
              );
              for (const [key, value] of Object.entries(
                (step.params ?? {}) as Record<string, string>
              )) {
                stepsJson = stepsJson.replace(
                  new RegExp(`\\\${${key}}`, "g"),
                  value
                );
              }
              steps.push(...JSON.parse(stepsJson));
            } else {
              steps.push(step);
            }
          });
          data.steps = steps;
          setQuestionnaire(data);
          return;
        } catch {}
      }
    })();
  }, [json, setQuestionnaire]);
  const loadFullQuestionnaire = useCallback(async () => {
    const questionnaire = await api.get("/questionnaire/" + questionnaireId);
    setJson(JSON.stringify(questionnaire ?? { steps: [] }, null, 4));
    setLoading(false);
  }, [questionnaireId, setJson]);
  useEffect(() => {
    loadFullQuestionnaire();
  }, [loadFullQuestionnaire]);
  return (
    <div className="Root-Editor">
      <div className="Flex-Row">
        <Text t-22>JSON을 입력하세요</Text>
        <Button
          type={"confirm"}
          caption={"Save"}
          onClick={async () => {
            if (!json) {
              return;
            }
            setLoading(true);
            setJson(JSON.stringify(JSON.parse(json), null, 4));
            await api.post(`/questionnaire/${questionnaireId}`, {
              title: JSON.parse(json).title ?? "",
              steps: JSON.parse(json).steps ?? [],
            });
            alert("질문지가 저장되었습니다.");
            setLoading(false);
          }}
        />
      </div>
      {error && <h5 className="Text-Error">{error}</h5>}
      {loading ? (
        <div className="Loader-large" />
      ) : (
        <textarea
          className="Root-Editor-Input"
          value={json}
          onChange={(e) => {
            try {
              JSON.parse(e.target.value);
              setJson(e.target.value);
              setError(undefined);
            } catch (err: any) {
              setJson(e.target.value);
              setError(err.message);
            }
          }}
        />
      )}
    </div>
  );
}

export default Editor;
