import _ from "lodash";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../common/api";
import NavBar from "../../common/NavBar/NavBar";
import Button from "../../layouts/Button";
import Divider from "../../layouts/Divider";
import HFlex from "../../layouts/HFlex";
import Image from "../../layouts/Image";
import Text from "../../layouts/Text";
import TextArea from "../../layouts/TextArea";
import VFlex from "../../layouts/VFlex";
import { getComponentNameByVar, Inquiry } from "../Main/Profile";

function LawyerInquiry() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<"question" | "detail">(
    "question"
  );
  const [inquiry, setInquiry] = useState<Inquiry>();
  const loadInquiry = useCallback(async () => {
    setInquiry(await api.get(`inquiry/${id}`));
  }, [id]);
  useEffect(() => {
    loadInquiry();
  }, [loadInquiry]);

  const [answer, setAnswer] = useState("");
  return (
    <VFlex g-16 p-16-brl width={"100%"} style={{ maxWidth: 430 }}>
      <NavBar profile={false} title="뒤로 가기" />
      <VFlex g-16 ovf-s-d>
        <HFlex g-8>
          <VFlex
            height={40}
            p-12-rl
            bd-pt
            bc-pt={selectedTab === "question"}
            bdr-40
            c-c
            onClick={() => setSelectedTab("question")}
          >
            <Text t-16-pt t-16-wt={selectedTab === "question"}>
              질문내용
            </Text>
          </VFlex>
          <VFlex
            height={40}
            p-12-rl
            bd-pt
            bc-pt={selectedTab === "detail"}
            bdr-40
            c-c
            onClick={() => setSelectedTab("detail")}
          >
            <Text t-16-pt t-16-wt={selectedTab === "detail"}>
              선택내용
            </Text>
          </VFlex>
        </HFlex>
        {selectedTab === "question" ? (
          <VFlex g-8>
            <VFlex g-8>
              <VFlex g-8 p-16 bc-wt bdr-12>
                <Text t-16>질문내용</Text>
                <Text t-14-g7>{inquiry?.question}</Text>
              </VFlex>
              <VFlex g-10 p-16 bc-wt bdr-12>
                <Text t-16>나의 답변</Text>
                {inquiry?.answers.map((answer, j) => (
                  <VFlex key={j} g-10>
                    <HFlex g-8>
                      <Image
                        bdr-40
                        d-40
                        src={
                          "https://cdn-icons-png.flaticon.com/512/6112/6112948.png"
                        }
                        alt="profile"
                      />
                      <VFlex g-4>
                        <Text t-16>{answer.user.name} 변호사</Text>
                        <Text t-12>
                          {answer.user.organization} (
                          {answer.user.organizationPhone})
                        </Text>
                        <Text t-12>
                          전화번호: {answer.user.phone?.slice(0, 3)}-
                          {answer.user.phone?.slice(3, 7)}-
                          {answer.user.phone?.slice(7, 11)}
                        </Text>
                        {answer.user.test && (
                          <Text t-12>
                            {answer.user.test} {answer.user.testNumber}회 (
                            {answer.user.test === "사법시험"
                              ? parseInt(answer.user.testNumber) + 1964
                              : answer.user.test === "변호사시험"
                              ? parseInt(answer.user.testNumber) + 2011
                              : answer.user.test === "군법무관 임용시험"
                              ? parseInt(answer.user.testNumber) + 1987
                              : ""}
                            년 취득)
                          </Text>
                        )}

                        <Text t-12>
                          {answer.user.educationCareerHistory
                            ?.split("\n")
                            .map((line) => (
                              <Text key={line} t-12>
                                {line}
                              </Text>
                            ))}
                        </Text>
                      </VFlex>
                    </HFlex>
                    <VFlex bd-g8 bdr-12 p-12-rl p-8-tb>
                      <Text t-14>{answer.answer}</Text>
                    </VFlex>
                    {j < inquiry?.answers.length - 1 && <Divider />}
                  </VFlex>
                ))}
              </VFlex>
            </VFlex>
            <VFlex g-8>
              <VFlex g-12 p-16 bc-wt bdr-12>
                <Text t-16>답변하기</Text>
                <HFlex g-8>
                  <Image
                    bdr-40
                    d-40
                    src={
                      "https://cdn-icons-png.flaticon.com/512/6112/6112948.png"
                    }
                    alt="profile"
                  />
                  <Text t-16>변호사 답변</Text>
                </HFlex>
                <TextArea
                  bdr-8
                  bd-g8
                  p-8-tb
                  p-12-rl
                  dh-85
                  value={answer}
                  onChangeValue={(val) => {
                    setAnswer(val);
                  }}
                />
                <Button
                  type="secondary"
                  caption="답변 올리기"
                  onClick={async () => {
                    setAnswer("");
                    setInquiry(
                      await api.post(`inquiry/${inquiry?.id}/answer`, {
                        answer,
                      })
                    );
                    alert("답변이 올라갔습니다.");
                  }}
                />
              </VFlex>
            </VFlex>
          </VFlex>
        ) : (
          <VFlex g-8>
            <Text t-16>선택내용</Text>
            {_.entries(inquiry?.form)
              .map(([key, value]) => [
                getComponentNameByVar(
                  _.flatten(inquiry?.steps.map((s) => s.components)),
                  key
                ),
                `${value}${value.unit ?? ""}`,
              ])
              .filter(([key, value]) => !_.isNil(key) && !_.isNil(value))
              .map(([key, value], j) => (
                <VFlex key={j}>
                  <Text t-12-g7>{key}</Text>
                  <Text t-14>{value}</Text>
                </VFlex>
              ))}
            <Button
              type="secondary"
              caption="결과 페이지로 이동"
              onClick={() => navigate(`/questionnaire?inquiry=${inquiry?.id}`)}
            />
          </VFlex>
        )}
      </VFlex>
    </VFlex>
  );
}

export default LawyerInquiry;
