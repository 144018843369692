import { HTMLAttributes } from "react";

export enum SvgIconType {
  LawyerProfile = "lawyer_profile",
  PremiumTemplate = "premium_template",
  FreeTemplate = "free_template",
  Download = "download",
  Cancel = "cancel",
  Eye = "eye",
  EyeHide = "eye_hide",
  Logo = "logo",
  LogoText = "logo_text",
  LogoTextLarge = "logo_text_large",
  LogoNaver = "logo_naver",
  LogoKakao = "logo_kakao",
  LogoApple = "logo_apple",
  ArrowBack = "arrow_back",
  ArrowBackWhite = "arrow_back_white",
  ArrowForward = "arrow_forward",
  UnfairProfit = "unfair_profit",
  Divorce = "divorce",
  Fraud = "fraud",
  Indemnification = "indemnification",
  Lease = "lease",
  Loan = "loan",
  More = "more",
  RealEstate = "real_estate",
  IconCalendar = "icon_calendar",
  IconCheck = "icon_check",
  IconMoney = "icon_money",
  IconSelect = "icon_select",
  Question = "question",
  Check = "check",
  AnonymousProfile = "anonymous_profile",
  Lock = "lock",
  Document = "document",
  ChevronRight = "chevron_right",
  CheckedBox = "checked_box",
  UncheckedBox = "unchecked_box",
  Home = "home",
  Plus = "plus",
  Plus2 = "plus2",
}

const SvgIcon = ({
  icon,
  size,
  width,
  height,
  shadow,
  style,
  ...props
}: {
  icon: SvgIconType;
  size?: number;
  width?: number;
  height?: number;
  shadow?: boolean;
} & HTMLAttributes<HTMLImageElement>) => {
  return (
    <img
      style={{
        ...(width || size ? { width: width ?? size } : {}),
        ...(height || size ? { height: height ?? size } : {}),
        ...(shadow
          ? {
              filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))",
            }
          : {}),
        ...style,
      }}
      src={require(`./${icon}.svg`)}
      alt={`icon_${icon.toString()}`}
      {...props}
    />
  );
};

export default SvgIcon;
