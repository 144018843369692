import NavBar from "../../common/NavBar/NavBar";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";
import VFlex from "../../layouts/VFlex";

function MarketingAgreement() {
  return (
    <VFlex p-16-brl a-c width={"100%"} style={{ maxWidth: 430 }}>
      <HFlex as-st>
        <NavBar profile={false} />
      </HFlex>
      <Text t-22>마케팅 수신동의</Text>
    </VFlex>
  );
}

export default MarketingAgreement;
