import React from "react";
import api, { baseURL } from "../../common/api";
import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";
import SvgIcon, { SvgIconType } from "../../svg";

export function DownloadTemplatePanel({
  inquiry,
  inquiryId,
  setInquiry,
  setStore,
  template,
  user,
}: {
  inquiry: { paid: boolean } & any;
  inquiryId: string | null;
  setInquiry: React.Dispatch<any>;
  setStore: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  template: {
    templateName: string;
    templatePrice: number;
    templateDescription?: string;
  };
  user: { name: string } & any;
}) {
  type templateNameDataModel = {
    name: string;
    subName: string;
  };

  const splittedTemplateName: string[] = template.templateName.split("_");
  const templateNameDM: templateNameDataModel = {
    name: splittedTemplateName[0],
    subName: splittedTemplateName[1],
  };

  return (
    <HFlex
      width="100%"
      height="84px"
      g-8
      bd-g5
      a-c
      j-c
      style={{
        justifyContent: "space-between",
        borderRadius: "8px",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 12,
        paddingRight: 12,
      }}
    >
      <SvgIcon
        icon={
          !template.templatePrice || template.templatePrice === 0
            ? SvgIconType.FreeTemplate
            : SvgIconType.PremiumTemplate
        }
        size={56}
        style={{ paddingBottom: 4 }}
      />
      <VFlex g-4 a-s j-s height="100%">
        <HFlex g-4 c-c>
          <Text t-16-b-pt2 style={{ marginTop: 2 }}>
            {templateNameDM.name}
          </Text>
          <HFlex
            c-c
            style={{
              padding: "2px 4px",
              backgroundColor: "#e2f6fa",
              borderRadius: "4px",
            }}
          >
            <Text t-10-b-pt2>{templateNameDM.subName}</Text>
          </HFlex>
        </HFlex>
        <Text
          t-12-g7
          style={{
            width: "160px",
            height: "32px",
            overflowX: "hidden",
            overflowY: "visible",
            overflowWrap: "break-word",
            wordBreak: "keep-all",
            textOverflow: "ellipsis",
          }}
        >
          {template.templateDescription}
        </Text>
      </VFlex>
      <VFlex
        a-c
        g-4
        style={{
          cursor: "pointer",
          paddingTop: 4,
        }}
        onClick={async () => {
          if (inquiry.paid || !template.templatePrice) {
            const downloadId = await api.get(
              `/inquiry/${inquiryId}/downloadId?templateName=${template.templateName}`
            );
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  message: "download_template",
                  data: { downloadId, templateName: template.templateName },
                })
              );
            } else {
              window.location.href = `${baseURL}inquiry/${downloadId}/download`;
            }
          } else {
            const payment = await api.post("/payment", {
              templateName: template.templateName,
              amount: template.templatePrice,
            });
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  message: "kakao_pay",
                  userCode: "imp37405014",
                  data: {
                    name: "올로양식",
                    merchant_uid: payment.id,
                    amount: template.templatePrice,
                    buyer_name: user?.name,
                    buyer_email: "",
                    buyer_tel: "",
                    buyer_addr: "",
                    buyer_postcode: "",
                    redirect_url: `${baseURL}payment/approve?redirect=${encodeURIComponent(
                      window.location.href
                    )}`,
                  },
                })
              );
            } else {
              window.IMP.request_pay(
                {
                  pg: "kakaopay",
                  merchant_uid: payment.id,
                  name: "올로 양식",
                  amount: template.templatePrice,
                  buyer_email: "",
                  buyer_name: user?.name,
                  buyer_tel: "",
                  buyer_addr: "",
                  buyer_postcode: "",
                  m_redirect_url: `${baseURL}payment/approve?redirect=${encodeURIComponent(
                    window.location.href
                  )}`,
                },
                async (rsp: any) => {
                  await api.get(
                    `/payment/approve?imp_uid=${rsp.imp_uid}&merchant_uid=${rsp.merchant_uid}&imp_success=${rsp.success}&error_msg=${rsp.error_msg}`
                  );
                  const inquiry = await api.get(`/inquiry/${inquiryId}`);
                  setStore(inquiry.form);
                  setInquiry(inquiry);
                  if (!rsp.success) {
                    alert("결제 실패");
                  }
                }
              );
            }
          }
        }}
      >
        <SvgIcon
          icon={SvgIconType.Download}
          style={{
            width: 40,
            height: 40,
            justifyContent: "center",
          }}
        />
        <Text t-12-b-pt2>
          {template.templatePrice > 0 ? `${template.templatePrice}원` : "FREE"}
        </Text>
      </VFlex>
    </HFlex>
  );
}
