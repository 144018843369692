import NavBar from "../../common/NavBar/NavBar";
import { useLocation, useNavigate } from "react-router-dom";
import Flex from "../../layouts/Flex";
import Text from "../../layouts/Text";
import SvgIcon, { SvgIconType } from "../../svg";
import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Footer from "../Footer";
import Image from "../../layouts/Image";
import Absolute from "../../layouts/Absolute";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext, UserRole } from "../../App";
import { getComponentNameByVar, Inquiry } from "./Profile";
import api from "../../common/api";
import Divider from "../../layouts/Divider";
import _ from "lodash";
import { format, isValid, parseISO } from "date-fns";
import Button from "../../layouts/Button";
import { useCookies } from "react-cookie";

function CaseButton({
  locked,
  icon,
  iconSize = 44,
  disabled,
  title,
  subtitle,
  onClick,
  style,
}: {
  locked?: boolean;
  icon?: SvgIconType;
  iconSize?: number;
  disabled?: boolean;
  title?: string;
  subtitle?: string;
  style?: any;
  onClick?: () => void;
}) {
  if (!icon) {
    return <Flex height={140} />;
  }
  return (
    <VFlex
      g-8={disabled}
      g-12={!disabled}
      c-c
      f-1
      bc-g9={!disabled}
      bdr-12
      bd={!disabled}
      height={140}
      clickable
      style={{
        boxShadow: !disabled ? "0px 4px 4px rgba(0, 0, 0, 0.07)" : "none",
        backgroundColor: disabled ? "rgb(248, 248, 248)" : "",
        ...style,
      }}
      onClick={locked ? undefined : onClick}
      rel
    >
      <VFlex c-c height={disabled ? "fit-content" : 44}>
        <SvgIcon icon={icon} size={iconSize} />
      </VFlex>
      <VFlex a-c>
        <Text
          t-14={!disabled}
          t-12-g7={disabled}
          t-c
          style={{
            whiteSpace: "pre-line",
            textAlign: "center",
          }}
        >
          {title}
        </Text>
        {subtitle && <Text t-12>{subtitle}</Text>}
      </VFlex>
      {locked && <Absolute bc-wt style={{ opacity: 0.8 }}></Absolute>}
      {locked && (
        <Absolute>
          <HFlex c-c height={"100%"}>
            <Text t-18>출시예정</Text>
          </HFlex>
        </Absolute>
      )}
    </VFlex>
  );
}

function Main() {
  const { user, login } = useContext(AppContext)!;
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, , removeCookie] = useCookies(["redirect"]);

  const [inquiries, setInquiries] = useState<Inquiry[]>([]);
  const state = location.state;

  const loadInquiries = useCallback(async () => {
    setInquiries(await api.get(`inquiry/inquiries/asked`));
  }, []);

  //  redirect 쿠키확인후 존재시 해당 url로 이동
  //  다른화면을 시작점으로 로그인했을시 그화면으로 되돌아가기 위함
  useEffect(() => {
    if (cookies && cookies["redirect"]) {
      let redirectUrl = cookies["redirect"];
      removeCookie("redirect");
      navigate(redirectUrl);
    }
  }, [cookies, navigate, removeCookie]);

  useEffect(() => {
    loadInquiries();
  }, [loadInquiries]);

  const [selectedTab, setSelectedTab] = useState<
    | "user"
    | "loan_question"
    | "lease_question"
    | "search"
    | "form"
    | "divorce_question"
    | "employment_question"
  >("user");

  if (
    !user?.anonymous &&
    user?.role === UserRole.LAWYER &&
    !user?.approved &&
    state !== "lawyerApprovalPending"
  ) {
    // 변호사 인증 진행중인 회원일 경우
    if (user.name || user.organization || user.test || user.testNumber) {
      return (
        <VFlex g-32 width={"100%"} height={"100%"} style={{ maxWidth: 430 }}>
          <VFlex g-32 p-16-rl f-1 height="100%">
            <NavBar logo title={"ALLaw"} />
            <VFlex
              g-16
              c-c
              width="100%"
              style={{ position: "absolute", bottom: "50%", left: 0 }}
            >
              <VFlex g-4 a-c>
                <Text as-c t-16>
                  변호사 인증 중입니다.
                </Text>
                <Text as-c t-14-g7 style={{ textAlign: "center" }}>
                  회원자격을 부여받으신 후<br></br>변호사로서 올로를 이용하실 수
                  있습니다.
                </Text>
              </VFlex>
              <Button
                type="primary"
                caption="홈으로"
                onClick={() => {
                  navigate("/", {
                    state: "lawyerApprovalPending",
                  });
                }}
              />
            </VFlex>
          </VFlex>
        </VFlex>
      );
    }
    // 아예 변호사 인증을 신청하지 않은 회원일 경우
    else if (user?.role === UserRole.LAWYER) {
      return (
        <VFlex g-32 width={"100%"} height={"100%"} style={{ maxWidth: 430 }}>
          <VFlex g-32 p-16-rl f-1 height="100%">
            <NavBar logo title={"ALLaw"} />
            <VFlex
              g-16
              c-c
              width="100%"
              style={{ position: "absolute", bottom: "50%", left: 0 }}
            >
              <VFlex g-4 a-c>
                <Text as-c t-16>
                  변호사 인증이 필요합니다.
                </Text>
                <Text as-c t-14-g7 style={{ textAlign: "center" }}>
                  회원자격을 부여받으신 후<br></br>변호사로서 올로를 이용하실 수
                  있습니다.
                </Text>
              </VFlex>
              <Button
                type="primary"
                caption="변호사 인증하기"
                onClick={() => {
                  navigate("/lawyer/signup/info");
                }}
              />
            </VFlex>
          </VFlex>
        </VFlex>
      );
    }
  }
  // 일반인 회원 화면|| 인증된 변호사 회원 화면
  return (
    <VFlex g-32 width={"100%"} height={"100%"} style={{ maxWidth: 430 }}>
      <VFlex g-16 p-16-rl m-8-t f-1>
        <VFlex g-16>
          <NavBar logo title={"ALLaw"} />
          <HFlex
            height={142}
            bc-g8
            bdr-12
            sized
            rel
            onClick={() => user?.anonymous && login()}
          >
            <Image width={"100%"} src="banner.png" />
            {user?.anonymous && (
              <Absolute bottom={18} left={19} top={"auto"}>
                <Text t-22-wt>로그인/회원가입</Text>
              </Absolute>
            )}
          </HFlex>
        </VFlex>

        <VFlex g-16 width="100%">
          {!user?.anonymous &&
          user?.role === UserRole.LAWYER &&
          state !== "lawyerApprovalPending" ? (
            <HFlex
              width={"100%"}
              ovf-s-d
              g-6
              style={{ flexWrap: "wrap", overflowWrap: "normal" }}
            >
              <VFlex
                sized
                p-8-rl
                p-4-tb
                style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)" }}
                bd-pt2
                bc-pt2={selectedTab === "user"}
                bdr-40
                c-c
                onClick={() => setSelectedTab("user")}
              >
                <Text t-12-pt2 m-2-t t-12-wt={selectedTab === "user"}>
                  법률주제
                </Text>
              </VFlex>
              <VFlex
                sized
                p-8-rl
                p-4-tb
                style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)" }}
                bd-pt2
                bc-pt2={selectedTab === "loan_question"}
                bdr-40
                c-c
                onClick={() => setSelectedTab("loan_question")}
              >
                <Text t-12-pt2 m-2-t t-12-wt={selectedTab === "loan_question"}>
                  대여금 질문
                </Text>
              </VFlex>
              <VFlex
                sized
                p-8-rl
                p-4-tb
                style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)" }}
                bd-pt2
                bc-pt2={selectedTab === "lease_question"}
                bdr-40
                c-c
                onClick={() => setSelectedTab("lease_question")}
              >
                <Text t-12-pt2 m-2-t t-12-wt={selectedTab === "lease_question"}>
                  임대차 질문
                </Text>
              </VFlex>
              <VFlex
                sized
                p-8-rl
                p-4-tb
                style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)" }}
                bd-pt2
                bc-pt2={selectedTab === "employment_question"}
                bdr-40
                c-c
                onClick={() => setSelectedTab("employment_question")}
              >
                <Text
                  t-12-pt2
                  m-2-t
                  t-12-wt={selectedTab === "employment_question"}
                >
                  근로기준법 질문
                </Text>
              </VFlex>
              <VFlex
                sized
                p-8-rl
                p-4-tb
                bd-g3
                bc-g3={selectedTab === "divorce_question"}
                bdr-40
                c-c
                //onClick={() => setSelectedTab("divorce_question")}
                style={{ opacity: 0.3 }}
              >
                <Text
                  t-12-g7
                  m-2-t
                  t-12-wt={selectedTab === "divorce_question"}
                >
                  이혼 질문
                </Text>
              </VFlex>

              <VFlex
                sized
                p-8-rl
                p-4-tb
                bd-g3
                bc-g3={selectedTab === "search"}
                bdr-40
                c-c
                //onClick={() => setSelectedTab("search")}
                style={{ opacity: 0.3 }}
              >
                <Text t-12-g7 m-2-t t-12-wt={selectedTab === "search"}>
                  판례 검색
                </Text>
              </VFlex>
              <VFlex
                sized
                p-8-rl
                p-4-tb
                bd-g3
                bc-g3={selectedTab === "search"}
                bdr-40
                c-c
                //onClick={() => setSelectedTab("search")}
                style={{ opacity: 0.3 }}
              >
                <Text t-12-g7 m-2-t t-12-wt={selectedTab === "search"}>
                  서류 자동화
                </Text>
              </VFlex>
            </HFlex>
          ) : (
            <HFlex a-c g-8>
              <Text t-22>법률주제</Text>
              <Text t-14-g7>궁금한 주제를 선택해주세요</Text>
            </HFlex>
          )}
          {selectedTab === "user" ? (
            <VFlex g-8 c-c width="100%">
              <HFlex g-8 width="100%">
                <CaseButton
                  icon={SvgIconType.Loan}
                  title={"빌려준돈/빌린돈\n(대여금)의 모든 것"}
                  onClick={() => navigate("questionnaire?questionnaire=1001")}
                />
                <CaseButton
                  icon={SvgIconType.Home}
                  title={"월세/전세계약\n(임대차)의 모든 것"}
                  onClick={() => navigate("questionnaire?questionnaire=2001")}
                />
                {/*
                <CaseButton
                  locked={true}
                  icon={SvgIconType.RealEstate}
                  title={"부동산"}
                  onClick={() => navigate("case/realestate")}
                /> */}
              </HFlex>
              <HFlex c-c g-8 width="100%">
                {/* <CaseButton
                  locked={true}
                  icon={SvgIconType.Divorce}
                  title={"이혼/재산분할의\n모든 것"}
                  onClick={() => navigate("case/divorce")}
                />*/}
                <CaseButton
                  // locked={true}
                  icon={SvgIconType.Fraud}
                  title={"고용/노동관계\n(근로기준법)의 모든 것"}
                  onClick={() => navigate("questionnaire?questionnaire=3001")}
                />
                <CaseButton
                  // locked={true}
                  icon={SvgIconType.Plus2}
                  iconSize={24}
                  title={"법률주제는\n지속적으로 추가될 예정입니다"}
                  style={{ PointerEvent: "none" }}
                  disabled
                  onClick={() => navigate("")}
                />
                {/*
                <CaseButton
                  locked={true}
                  icon={SvgIconType.UnfairProfit}
                  title={"부당이득"}
                  onClick={() => navigate("case/unfairprofit")}
                /> */}
              </HFlex>
            </VFlex>
          ) : (
            <VFlex g-16>
              {inquiries
                .filter((inquiry) => {
                  if (selectedTab === "lease_question") {
                    return inquiry.questionnaireId === "2001";
                  } else if (selectedTab === "loan_question") {
                    return inquiry.questionnaireId === "1001";
                  } else if (selectedTab === "employment_question") {
                    return inquiry.questionnaireId === "3001";
                  } else {
                    return false;
                  }
                })
                .map((inquiry, i) => (
                  <VFlex key={i} g-8 p-16 bc-wt bdr-12>
                    <VFlex key={i} g-10>
                      <VFlex>
                        <HFlex a-c g-4>
                          <Text t-18 f-1>
                            {selectedTab === "loan_question"
                              ? "대여금"
                              : selectedTab === "lease_question"
                              ? "임대차"
                              : selectedTab === "employment_question"
                              ? "근로기준법"
                              : ""}
                          </Text>
                          <Text t-12-g7 l-1>
                            {format(new Date(inquiry.createdAt), "yyyy-MM-dd")}
                          </Text>
                        </HFlex>
                        <Text t-12>{inquiry.title}</Text>
                      </VFlex>
                      <Divider />
                      <Text t-16>질문내용</Text>
                      <Text t-14>{inquiry.question}</Text>
                      <Divider />
                      <Text t-16>선택내용</Text>
                      {_.entries(inquiry.form)
                        .map(([key, value]) => [
                          getComponentNameByVar(
                            _.flatten(inquiry.steps.map((s) => s.components)),
                            key
                          ),
                          _.isObject(value)
                            ? `${(value as any)["value"]}${
                                (value as any)["unit"] ?? ""
                              }`
                            : isValid(parseISO(value))
                            ? format(new Date(value), "yyyy-MM-dd")
                            : value,
                        ])
                        .filter(
                          ([key, value]) => !_.isNil(key) && !_.isNil(value)
                        )
                        .map(([key, value], j) => (
                          <VFlex key={j}>
                            <Text t-12-g7>{key}</Text>
                            <Text t-14>{value}</Text>
                          </VFlex>
                        ))}
                      <Divider />
                      <Button
                        type="secondary"
                        caption="답변하기"
                        onClick={() =>
                          navigate(`/lawyer/inquiry/${inquiry.id}`)
                        }
                      />
                    </VFlex>
                  </VFlex>
                ))}
            </VFlex>
          )}
        </VFlex>
      </VFlex>
      <Footer />
    </VFlex>
  );
}

export default Main;
