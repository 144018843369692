import _ from "lodash";
import { HTMLAttributes } from "react";
import {
  MiscellaneousProps,
  propsToClasses,
  restOfProps,
} from "./index_generated";
import "./layout.scss";

type AbsoluteProps = {
  classes?: string[];
  top?: number | string;
  left?: number | string;
  right?: number | string;
  bottom?: number | string;
} & HTMLAttributes<HTMLDivElement> &
  MiscellaneousProps;

const Absolute = ({
  classes = [],
  children,
  top = 0,
  left = 0,
  right = 0,
  bottom = 0,
  style,
  ...props
}: AbsoluteProps) => {
  return (
    <div
      className={_.compact([...propsToClasses(props), ...classes]).join(" ")}
      style={{
        position: "absolute",
        ...(top !== "auto" && !_.isNil(top) ? { top: `${top}px` } : {}),
        ...(left !== "auto" && !_.isNil(left) ? { left: `${left}px` } : {}),
        ...(right !== "auto" && !_.isNil(right) ? { right: `${right}px` } : {}),
        ...(bottom !== "auto" && !_.isNil(bottom)
          ? { bottom: `${bottom}px` }
          : {}),
        ...style,
      }}
      {...restOfProps(props)}
    >
      {children}
    </div>
  );
};

export default Absolute;
