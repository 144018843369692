import NavBar from "../../common/NavBar/NavBar";
import { Link, useNavigate } from "react-router-dom";
import Text from "../../layouts/Text";
import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import { useContext, useEffect, useMemo, useState } from "react";
import Button from "../../layouts/Button";
import TextInput from "../../layouts/TextInput";
import { AppContext } from "../../App";
import SvgIcon, { SvgIconType } from "../../svg";
import _ from "lodash";
import api from "../../common/api";

function Signup() {
  const { login } = useContext(AppContext)!;
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [usernameAvailable, setUsernameAvailable] = useState<boolean>();
  const [usernameValid, setUsernameValid] = useState<boolean>();
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState<boolean>();
  useEffect(() => {
    if (password) {
      setPasswordValid(
        /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/.test(password)
      );
    }
  }, [password]);
  const passwordConfirmValid = useMemo(
    () => password === confirmPassword,
    [password, confirmPassword]
  );
  const checkUsername = useMemo(
    () =>
      _.debounce(async (value) => {
        if (value) {
          if (value.length >= 6) {
            setUsernameAvailable(
              !(await api.post(`/user/check?username=${value}`))
            );
            setUsernameValid(true);
          } else {
            setUsernameValid(false);
          }
        } else {
          setUsernameValid(undefined);
        }
      }, 200),
    []
  );

  const [agreed1, setAgreed1] = useState(false);
  const [agreed2, setAgreed2] = useState(false);
  const [agreed3, setAgreed3] = useState(false);
  const [agreed4, setAgreed4] = useState(false);
  const isReady = useMemo(
    () =>
      usernameValid &&
      usernameAvailable &&
      passwordValid &&
      passwordConfirmValid &&
      email &&
      agreed1 &&
      agreed2 &&
      agreed3,
    [
      usernameValid,
      usernameAvailable,
      passwordValid,
      passwordConfirmValid,
      email,
      agreed1,
      agreed2,
      agreed3,
    ]
  );
  return (
    <VFlex a-c width={"100vw"} height={"100vh"} style={{ background: "#FFF" }}>
      <VFlex
        g-32
        width={"100vw"}
        height={"100vh"}
        style={{ maxWidth: 430, background: "#FFF" }}
        p-16-brl
      >
        <NavBar profile={false} title={"회원가입"} />
        <VFlex g-16 style={{ marginTop: 19 }}>
          <VFlex g-4>
            <TextInput
              caption="아이디"
              placeholder={"아이디"}
              value={username}
              onChangeValue={async (value) => {
                checkUsername(value);
                setUsername(value);
              }}
              autoFocus
            />
            {usernameValid === true ? (
              usernameAvailable === true ? (
                <Text t-12-pt>사용 가능한 아이디입니다.</Text>
              ) : usernameAvailable === false ? (
                <Text t-12-bb>이미 사용중인 아이디입니다.</Text>
              ) : null
            ) : usernameValid === false ? (
              <Text t-12-bb>아이디는 6자 이상이어야 합니다.</Text>
            ) : null}
          </VFlex>
          <TextInput
            caption={"이메일"}
            placeholder={"이메일"}
            value={email}
            onChangeValue={(value) => setEmail(value)}
          />
          <VFlex g-4>
            <TextInput
              caption={"비밀번호 입력"}
              placeholder={"비밀번호 입력"}
              value={password}
              type={"password"}
              onChangeValue={(value) => setPassword(value)}
              hasReveal
            />
            {passwordValid === false && (
              <Text t-12-bb>영문 + 숫자 + 특수문자 + 8자 이상 조합</Text>
            )}
          </VFlex>
          <VFlex g-4>
            <TextInput
              caption={"비밀번호 확인"}
              placeholder={"비밀번호 확인"}
              value={confirmPassword}
              type={"password"}
              onChangeValue={(value) => setConfirmPassword(value)}
              hasReveal
            />
            {!!confirmPassword && passwordConfirmValid === false && (
              <Text t-12-bb>비밀번호가 일치하지 않습니다.</Text>
            )}
          </VFlex>
        </VFlex>
        <VFlex g-20 f-1>
          <VFlex g-8 p-32-tb g-16>
            <HFlex g-8>
              <SvgIcon
                size={24}
                icon={
                  agreed1 ? SvgIconType.CheckedBox : SvgIconType.UncheckedBox
                }
                onClick={() => setAgreed1(!agreed1)}
              />

              <Text t-16-g7 f-1>
                [필수] 만 14세 이상입니다.
              </Text>
            </HFlex>
            <HFlex g-8>
              <SvgIcon
                size={24}
                icon={
                  agreed2 ? SvgIconType.CheckedBox : SvgIconType.UncheckedBox
                }
                onClick={() => setAgreed2(!agreed2)}
              />
              <HFlex f-1 g-8>
                <Text t-16-g7 f-1>
                  [필수] 서비스 이용약관 동의
                </Text>
                <Link to="/termsofservice" target="_blank">
                  <Text t-12-g7>내용보기</Text>
                </Link>
              </HFlex>
            </HFlex>
            <HFlex g-8>
              <SvgIcon
                size={24}
                icon={
                  agreed3 ? SvgIconType.CheckedBox : SvgIconType.UncheckedBox
                }
                onClick={() => setAgreed3(!agreed3)}
              />
              <HFlex f-1 g-8>
                <Text t-16-g7 f-1>
                  [필수] 개인정보 수집/이용 동의
                </Text>
                <Link to="/privacy_agreement" target="_blank">
                  <Text t-12-g7>내용보기</Text>
                </Link>
              </HFlex>
            </HFlex>
            <HFlex g-8 style={{ cursor: "pointer" }}>
              <SvgIcon
                size={24}
                icon={
                  agreed4 ? SvgIconType.CheckedBox : SvgIconType.UncheckedBox
                }
                onClick={() => setAgreed4(!agreed4)}
              />
              <VFlex f-1 g-4>
                <Text t-16-g7 f-1>
                  [선택] 마케팅 수신 동의
                </Text>
                <Text t-12-g7>
                  변호사 답변, 신규 서비스 소식, 이벤트 및 할인/혜택 등 다양한
                  정보를 카카오톡알림톡 또는 문자메시지로 받아보겠습니다.
                </Text>
              </VFlex>
            </HFlex>
          </VFlex>

          <HFlex as-st>
            <Button
              f-1
              enabled={!!isReady}
              caption="완료"
              type={"confirm"}
              style={{ height: "48px" }}
              onClick={async () => {
                try {
                  navigate("/otp", {
                    state: { username, password, email, agreed4 },
                  });
                } catch (e: any) {
                  if (e.response.data.message === "Username already exists") {
                    alert("이미 사용중인 아이디입니다.");
                  } else {
                    alert(e.response.data.message);
                  }
                }
              }}
            />
          </HFlex>
          <VFlex a-c style={{ marginBottom: 20 }}>
            <Text t-14>
              이미 계정이 있으신가요?{" "}
              <Text
                style={{ textDecoration: "underline" }}
                onClick={() => login()}
              >
                Log in
              </Text>
            </Text>
          </VFlex>
        </VFlex>
      </VFlex>
    </VFlex>
  );
}

export default Signup;
