import {
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Absolute from "./Absolute";
import HFlex from "./HFlex";
import Text from "./Text";
import VFlex from "./VFlex";
import SvgIcon, { SvgIconType } from "../svg";

function tryParseLocalize(str: string) {
  if (/^[0-9.,]+$/.test(str)) {
    try {
      str = str.slice(0, 20).replaceAll(",", "");
      if (str.endsWith(".")) {
        const i = str.indexOf(".");
        return parseFloat(str).toLocaleString() + (i === -1 ? "" : ".");
      }
      return parseFloat(str).toLocaleString();
    } catch {}
  }
  return str;
}

function TextInput({
  placeholder,
  isNumber,
  caption,
  required,
  disabled,
  value,
  onChangeValue,
  type,
  prefix,
  postfix,
  autoFocus,
  inputMode,
  hasReveal = false,
  error = false,
}: InputHTMLAttributes<HTMLInputElement> & {
  onChangeValue?: (value: string) => void;
  isNumber?: boolean;
  caption?: string;
  value: string | undefined;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  type?: InputHTMLAttributes<HTMLInputElement>["type"];
  prefix?: string;
  postfix?: string;
  inputMode?: InputHTMLAttributes<HTMLInputElement>["inputMode"];
  hasReveal?: boolean;
  autoFocus?: boolean;
  error?: boolean;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const [cancelMouseDown, setCancelMouseDown] = useState<boolean>(false);
  const [revealInput, setRevealInput] = useState(false);

  const isMobile = useMemo(
    () =>
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ),
    []
  );

  const toggleRevealText = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setRevealInput((prev) => !prev);
  };

  useEffect(() => {
    if (autoFocus) inputRef.current?.focus();
  }, [autoFocus]);

  return (
    <VFlex
      f-1
      g-6
      style={{
        pointerEvents: disabled ? "none" : "auto",
      }}
      rel
    >
      {caption && (
        <HFlex g-8>
          <Text t-16-g0>{caption}</Text>
          {required && <Text t-14-ee>*</Text>}
        </HFlex>
      )}
      <HFlex g-8 a-c>
        {prefix && <Text t-16>{prefix}</Text>}
        <HFlex
          f-1
          height={40}
          bc-g1
          style={{
            border: `1px solid ${
              error ? "#FF0000" : !focused ? "#D9D9D9" : "#004175"
            }`,
          }}
          bdr-8
          rel
          onClick={() => {
            setFocused(true);
            inputRef.current?.focus();
          }}
        >
          {!!placeholder && !focused && !value && (
            <HFlex
              abs
              p-12-rl
              a-c
              transition
              style={{
                top: 2,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <Text t-16-g5>{placeholder}</Text>
            </HFlex>
          )}
          <HFlex
            p-12-rl
            width="100%"
            style={{
              opacity: focused || caption || !!value ? 1 : 0,
            }}
          >
            <input
              style={{
                width: "100%",
                color: "#8C8C8C",
                fontSize: 16,
                fontWeight: 500,
              }}
              inputMode={inputMode}
              autoCapitalize="none"
              ref={inputRef}
              type={revealInput ? "text" : type}
              value={isNumber ? tryParseLocalize(value ?? "") : value ?? ""}
              onChange={(e) => {
                onChangeValue?.(
                  (isNumber
                    ? tryParseLocalize(e.target.value)
                    : e.target.value) || ""
                );
              }}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
            {hasReveal && !value && (
              <Absolute
                left={"auto"}
                top={10}
                right={16}
                onClick={!isMobile ? toggleRevealText : () => {}}
                onTouchEnd={isMobile ? toggleRevealText : () => {}}
                style={{
                  zIndex: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "fit-content",
                  height: "fit-content",
                  cursor: "pointer",
                  pointerEvents: "all",
                }}
              >
                <SvgIcon
                  icon={!revealInput ? SvgIconType.Eye : SvgIconType.EyeHide}
                  size={17}
                />
              </Absolute>
            )}

            {!!value && (!focused || cancelMouseDown) && (
              <Absolute
                left={"auto"}
                top={10}
                right={16}
                onMouseDown={() => setCancelMouseDown(true)}
                onClick={(e) => {
                  if (!isMobile) {
                    onChangeValue?.("");
                    e.stopPropagation();
                    setCancelMouseDown(false);
                  }
                }}
                onTouchEnd={(e) => {
                  if (isMobile) {
                    onChangeValue?.("");
                    e.stopPropagation();
                    setCancelMouseDown(false);
                  }
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "fit-content",
                  height: "fit-content",
                  cursor: "pointer",
                }}
              >
                <SvgIcon icon={SvgIconType.Cancel} size={20} />
              </Absolute>
            )}
          </HFlex>
        </HFlex>
        {postfix && <Text t-16>{postfix}</Text>}
      </HFlex>
    </VFlex>
  );
}

export default TextInput;
