import { ReactElement } from "react";
import { ComponentProps } from "..";
import HFlex from "../../../../layouts/HFlex";
import Text from "../../../../layouts/Text";
import VFlex from "../../../../layouts/VFlex";
import ComponentHeader, { ComponentHeaderProps } from "../ComponentHeader";
import "./RadioButton.css";
import _ from "lodash";
import { SvgIconType } from "../../../../svg";

export type RadioButtonComponentProps = {
  var: string;
  options: string[];
  description?: string;
  vertical: boolean;
  showRadio: boolean;
  showLabel: boolean;
  multi: boolean;
  wrapper: typeof HFlex | typeof VFlex;
} & ComponentHeaderProps;

function DefaultChip({
  Wrapper,
  component,
  i,
  setValue,
  subtitle,
  title,
  value,
}: ComponentProps<string | string[], RadioButtonComponentProps> & {
  Wrapper: typeof HFlex | typeof VFlex;
  i: number;
  subtitle?: string;
  title: string;
}) {
  return (
    <Wrapper
      c-c
      g-8
      c-pt={component.multi ? value?.includes(title) : title === value}
      bdw-2-pt={component.multi ? value?.includes(title) : title === value}
      bd-g5={!(component.multi ? value?.includes(title) : title === value)}
      p-16-rl
      p-8-tb
      key={i}
      onClick={() =>
        setValue(
          component.multi
            ? _.isArray(value)
              ? value.includes(title)
                ? value.filter((v: any) => v !== title)
                : [...value, title]
              : [title]
            : title === value
            ? undefined
            : title
        )
      }
      width={component.vertical ? "100%" : `${100 / component.options.length}%`}
      style={{ boxSizing: "border-box", borderRadius: 100 }}
    >
      {component.showRadio && (
        <HFlex
          width={20}
          height={20}
          bdw-2-pt
          bdr-16
          c-c
          style={{
            marginLeft: component.multi
              ? value?.includes(title)
                ? 15
                : 16
              : title === value
              ? 15
              : 16,
          }}
        >
          {(component.multi ? value?.includes(title) : title === value) && (
            <HFlex width={12} height={12} bc-pt bdr-16 />
          )}
        </HFlex>
      )}
      <VFlex f-1={component.vertical}>
        <Text>{title}</Text>
        {subtitle && <Text t-12-g7>{subtitle}</Text>}
      </VFlex>
    </Wrapper>
  );
}

function RadioButtonRenderer({
  component,
  value,
  setValue,
}: ComponentProps<string | string[], RadioButtonComponentProps>): ReactElement {
  const FlexWrapper = component.vertical ? VFlex : HFlex;
  const OppositeFlexWrapper = component.vertical ? HFlex : VFlex;
  return (
    <VFlex bdr-12 bc-g9 g-8 p-16 width="100%">
      <VFlex>
        <HFlex a-c g-12>
          <ComponentHeader
            {...component}
            icon={
              component.var === "endDateExists"
                ? SvgIconType.IconCalendar
                : component.var === "월임대료여부"
                ? SvgIconType.IconMoney
                : SvgIconType.IconSelect
            }
            subtitle={
              component.showLabel
                ? `${component.options.length}가지중 택일`
                : undefined
            }
            completed={!!value}
          />
        </HFlex>
        {component.description && <Text t-14-g7>{component.description}</Text>}{" "}
      </VFlex>
      <FlexWrapper a-st g-6={!component.vertical} g-10={component.vertical}>
        {component.options?.map((option, i) => {
          let title = option;
          let subtitle;
          if (_.isObject(option)) {
            title = option["title"];
            subtitle = option["subtitle"];
          }
          return (
            <DefaultChip
              Wrapper={OppositeFlexWrapper}
              component={component}
              i={i}
              setValue={(value) => {
                setValue(value);
              }}
              subtitle={subtitle}
              title={title}
              value={value}
            />
          );
        })}
      </FlexWrapper>
    </VFlex>
  );
}

export default RadioButtonRenderer;
