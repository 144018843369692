import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../../common/api";
import bluebird from "bluebird";
import Text from "../../../../layouts/Text";
import VFlex from "../../../../layouts/VFlex";
import Divider from "../../../../layouts/Divider";
import ComponentHeader, { ComponentHeaderProps } from "../ComponentHeader";
import { ComponentProps } from "..";

export type PrecedentComponentProps = {
  ids: string[];
} & ComponentHeaderProps;

export type PrecedentType = {
  id: string;
  caseId: string;
  caseName: string;
  precedent: string;
  headnote: string;
  holding: string;
};

function PrecedentRenderer({
  component,
  value,
  setValue,
}: ComponentProps<string, PrecedentComponentProps>): ReactElement {
  const [precedents, setPrecedents] = useState<PrecedentType[]>();
  useEffect(() => {
    (async () => {
      const precedents = await bluebird.map(component.ids, async (id) => {
        return await api.get(`precedent/${id}`);
      });
      setPrecedents(precedents);
    })();
  }, [component, setPrecedents]);
  return (
    <VFlex bdr-12 bc-g9 p-16>
      <ComponentHeader {...component} title={"관련판례"} />
      <VFlex m-8-t g-8>
        {precedents?.map(({ id, caseId }, i: number) => (
          <VFlex key={i}>
            <Link to={`/precedent/${id}`}>
              <Text t-16>{caseId}</Text>
            </Link>
            {i < component.ids.length - 1 && <Divider />}
          </VFlex>
        ))}
      </VFlex>
    </VFlex>
  );
}

export default PrecedentRenderer;
