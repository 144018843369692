import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
export const baseURL = process.env.REACT_APP_DEV
  ? `http://localhost:4101/`
  : `https://api.allaw.kr/`;
const client = axios.create({
  baseURL,
  withCredentials: true,
});

function handleResponse(res: AxiosResponse) {
  return res.data;
}

async function axiosGet<T = any>(url: string, config?: AxiosRequestConfig) {
  try {
    const res = await client.get(url, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    if (e instanceof AxiosError) {
      if (e.response?.status === 401) {
        if (window.location.pathname !== "/login") {
          //window.location.href = "/login";
        }
      } else if (e.response?.status !== 409) {
        alert(`Error: ${e.message}`);
      }
    }
    throw e;
  }
}

async function axiosPost<T = any>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  try {
    const res = await client.post(url, data, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    if (e instanceof AxiosError) {
      if (e.response?.status === 401) {
        if (window.location.pathname !== "/login") {
          // window.location.href = "/login";
        }
      } else if (e.response?.status !== 409) {
        alert(`Error: ${e.message}`);
      }
    }
    throw e;
  }
}

async function axiosPut<T = any>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  try {
    const res = await client.put(url, data, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    if (e instanceof AxiosError) {
      if (e.response?.status === 401) {
        if (window.location.pathname !== "/login") {
          //window.location.href = "/login";
        }
      } else if (e.response?.status !== 409) {
        alert(`Error: ${e.message}`);
      }
    }
    throw e;
  }
}

async function axiosDelete<T = any>(url: string, config?: AxiosRequestConfig) {
  try {
    const res = await client.delete(url, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    if (e instanceof AxiosError) {
      if (e.response?.status === 401) {
        if (window.location.pathname !== "/login") {
          //window.location.href = "/login";
        }
      } else if (e.response?.status !== 409) {
        alert(`Error: ${e.message}`);
      }
    }
    throw e;
  }
}

const api = {
  get: axiosGet,
  post: axiosPost,
  delete: axiosDelete,
  put: axiosPut,
};

export default api;
