import NavBar from "../../common/NavBar/NavBar";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";
import VFlex from "../../layouts/VFlex";

function TermsOfService() {
  return (
    <VFlex p-16-brl a-c width={"100%"} style={{ maxWidth: 430 }}>
      <HFlex as-st>
        <NavBar profile={false} />
      </HFlex>
      <Text t-22-m>이용약관</Text>
      <Text><br></br><br></br>본 약관은 엠에스(ms)컴퍼니(이하 "회사")가 제공하는 올로(ALLAW) 서비스(이하 "서비스")를 이용함에 있어 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제1조. 용어의 정의</strong><br></br>
본 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br></br>
1. "올로(ALLAW) 서비스"라 함은 회사가 운영하는 인터넷 또는 모바일 인터페이스에서 구현되는 단말기(PC, 모바일, 태블릿 등의 각종 유무선 장치를 포함)와 상관없이 회원이 이용할 수 있는 다음 각 호의 서비스를 의미합니다.<br></br>
① "클릭검색 서비스"라 함은 일반회원이 법률주제에 따라 제공된 선택지를 클릭함으로써 회사로부터 그에 따른 답변을 제공받는 서비스를 의미합니다.<br></br>
② "나홀로소송 양식제공 서비스"라 함은 일반회원이 클릭검색 서비스의 답변을 확인한 후, 회사로부터 선택한 내용에 따른 나홀로소송에 필요한 양식(작성을 위한 팁을 포함)을 제공받는 서비스를 의미합니다.<br></br>
③ "온라인상담 서비스"라 함은 일반회원이 클릭검색 서비스의 답변을 확인한 후 추가적으로 작성한 질문에 대하여 변호사회원이 답변을 하고, 해당 질문과 답변을 양 당사자에게 제공하는 서비스를 의미합니다.<br></br>
④ "서류자동화 서비스"라 함은 변호사회원이 클릭 및 내용 작성을 하면 회사가 법률업무에 필요한 각 서류를 자동으로 작성하여 제공하는 서비스를 의미합니다.<br></br>
⑤ "판례검색 서비스"라 함은 변호사회원이 법률업무에 필요한 다양한 판례를 키워드로 검색하여 제공받는 서비스를 의미합니다.<br></br>
2. "이용계약"이라 함은 회사가 제공하는 서비스의 이용과 관련하여 회사와 이용자 간에 체결하는 계약을 의미합니다.<br></br>
3. "회원"이라 함은 본 약관에 따라 회사와 이용계약을 체결하여 아이디를 발급받은 만 14세 이상의 고객으로서, 일반회원과 변호사회원을 포함합니다.<br></br>
4. "일반회원"이라 함은 회사의 약관에 동의하고 일반회원의 양식에 따라 계정을 등록한 자를 의미합니다.<br></br>
5. "변호사회원"이라 함은 대한민국 변호사 자격을 취득한 자로서 회사의 약관에 동의하고 변호사회원의 양식에 따라 계정을 등록한 자를 의미합니다.<br></br>
6. "아이디(ID)"라 함은 회원의 식별과 서비스 이용을 위하여 회원이 정하고 회사가 승인하는 문자 또는 숫자의 조합을 의미합니다.<br></br>
7. "비밀번호"라 함은 회원이 부여 받은 아이디(ID)와 일치되는 회원임을 확인하고 비밀보호를 위해 회원 자신이 정한 문자와 숫자 및 특수문자의 조합을 의미합니다.<br></br>
8. "탈퇴(해지)"란 회사 또는 회원이 이용계약을 해약하는 것을 의미합니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제2조. 약관의 게시와 개정</strong><br></br>
1. 회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 온라인 서비스 초기화면에 게시하거나 연결화면을 제공하는 방법으로 회원에게 공지합니다.<br></br>
2. 회사는 "약관의규제에관한법률", "정보통신망이용촉진및정보보호등에관한법률" 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.<br></br>
3. 회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을 명시하여 그 적용일자로부터 최소한 7일 전("회원"에게 불리하거나 중대한 사항의 변경은 30일 이전)부터 온라인 서비스 초기화면 또는 연결화면을 통해 게시하며, 기존 회원에게는 본 약관 제9조의 방법을 통하여 통지합니다.<br></br>
4. 회사가 약관을 개정할 경우에는 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 단, 개정약관 공지 시 회원이 동의 또는 거부의 의사표시를 하지 않으면 승낙한 것으로 간주하겠다는 내용도 함께 공지한 경우에는 회원이 약관 시행일까지 거부 의사를 표시하지 않는다면 개정약관에 동의한 것으로 간주할 수 있습니다.<br></br>
5. 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 해당 회원에 대해 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원 또는 회사는 이용계약을 해지할 수 있습니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제3조. 약관의 해석</strong><br></br>
1. 회사는 개별 서비스에 대해서는 별도의 이용약관 및 정책(이하 "개별 서비스 약관 등")을 둘 수 있으며, 해당 내용이 본 약관과 상충할 경우에는 개별 서비스 약관 등이 우선하여 적용됩니다.<br></br>
2. 본 약관에서 정하지 아니한 사항이나 해석에 대해서는 개별 서비스약관 등 및 "약관의규제에관한법률", "정보통신망이용촉진및정보보호등에관한법률", "변호사법" 등 관계법령에 따릅니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제4조. 회원가입 및 서비스 이용계약의 성립 등</strong><br></br>
1. 서비스 이용계약은 회원이 되려고 하는 자가 회사가 정한 이용약관 및 개인정보 수집 및 이용 동의서에 대하여 "동의"를 선택하고, 회사에서 요구하는 제반 정보를 제공하는 방법으로 서비스 이용을 신청한 후 이용 신청에 대하여 회사가 이를 승낙함으로써 회원가입이 완료됨과 동시에 성립합니다.<br></br>
2. 회사가 제공하는 서비스를 이용하고자 하는 변호사는 변호사회원 양식을 작성해야 하고, 일반인은 일반회원 양식을 작성해야 합니다.<br></br>
3. 이용자는 제1항의 이용 신청 시 본인의 실제정보를 기재하여야 합니다. 식별정보를 허위로 기재하거나 타인의 식별정보를 도용한 경우 회원은 본 약관에 의한 회원의 권리를 주장할 수 없고, 회사는 별도의 보상 없이 이용계약을 취소하거나 해지할 수 있습니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제5조. 이용 신청의 승낙과 제한</strong><br></br>
1. 회사는 이용자가 회사가 요구하는 정보에 대해 실제 정보를 정확히 기재하여 이용신청을 한 경우 상당한 이유가 없는 한 이용신청을 승낙합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있습니다.<br></br>
① 제4조를 위반하여 이용신청을 하는 경우<br></br>
② 휴대전화 승인 등 회사가 정한 인증 절차를 완료하지 않은 경우<br></br>
③ 이전에 회원자격을 상실한 적이 있는 자로서 회사의 회원 재가입 승낙을 얻지 못한 경우<br></br>
④ "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 및 그 밖의 관계 법령에서 금지하는 위법행위를 할 목적으로 이용신청을 하는 경우<br></br>
⑤ 만 14세 미만의 아동이 이용신청을 하는 경우<br></br>
⑥ 변호사회원 가입 승인을 위한 필수서류를 제출하지 않거나 문제가 있는 경우<br></br>
⑦ 그 밖에 1호 내지 4호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우<br></br>
2. 회사는 서비스 관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우, 서비스 상의 장애 또는 서비스 이용요금 결제수단의 장애가 발생한 경우 및 이에 준하는 사유로 이용신청의 승낙이 곤란한 경우에는 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.<br></br>
3. 회사가 제1항 단서 및 제2항에 따라 회원가입 신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를 이용신청자에게 알리도록 합니다.<br></br>
4. 본 약관에 따른 이용계약의 성립 시기는 회사가 이용신청절차 상에서 회원가입 완료를 표시한 시점으로 합니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제6조. 회원정보의 제공 및 변경</strong><br></br>
1. 회원은 본 약관에 의하여 회사에 정보를 제공하여야 하는 경우에는 진실된 정보를 제공하여야 하며, 허위정보 제공으로 인해 발생한 불이익에 대해서는 보호받지 못합니다.<br></br>
2. 회사는 향후 개별 서비스를 통해 회원의 동의 하에 개인정보를 추가적으로 수집할 수 있으며, 이에 따라 적법하게 제공된 회원의 개인정보는 회원 본인이 개인정보관리화면을 통하여 언제든지 열람하고 수정할 수 있습니다. 다만, 개별 서비스 관리를 위해 필요한 실명, 생년월일, 성별, 아이디(ID) 등은 수정이 불가능합니다.<br></br>
3. 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.<br></br>
4. 제3항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제7조. 개인정보의 보호 및 관리</strong><br></br>
1. 회사는 "개인정보보호법" 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 회원 개인정보의 보호 및 사용에 대해서는 관계법령 및 회사의 개인정보처리방침이 적용됩니다.<br></br>
2. 회사의 공식 사이트 이외의 단순히 링크된 제3자의 사이트 및 서비스에서는 회사의 개인정보처리방침이 적용되지 않습니다.<br></br>
3. 회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든 정보에 대해서 일체의 책임을 지지 않습니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제8조. 회원의 아이디 및 비밀번호 관리에 대한 의무</strong><br></br>
1. 회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다. 회원이 본인의 아이디와 비밀번호를 소홀히 관리하거나 제3자에게 이용을 승낙함으로써 발생하는 손해에 대하여는 회원에게 책임이 있습니다.<br></br>
2. 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.<br></br>
3. 제2항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.<br></br>
4. 제2항의 경우에 회사는 회원의 자격을 박탈할 수 있습니다.<br></br>
5. 회원은 정기적으로 비밀번호를 변경하여야 하며, 회사는 회원이 원하는 경우 언제든지 비밀번호를 변경할 수 있도록 합니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제9조. 회원에 대한 통지</strong><br></br>
1. 회사가 회원에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 회원이 지정한 전자우편, 전자메모, 회사 서비스 내 알림창 등으로 할 수 있습니다.<br></br>
2. 회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제10조. 일반회원 및 비회원의 권리 및 의무</strong><br></br>
1. 비회원이 회원가입 이전까지 회사가 제공하는 서비스를 이용함에 있어서 회사는 이에 대해 대가를 받지 않습니다.<br></br>
2. 일반회원이 회사가 제공하는 유료서비스를 이용할 경우 회사를 계약당사자로 하여 결제대행회사가 처리하도록 할 수 있고, 회사의 결제대행지원업무에 적극 협조할 의무가 있습니다.<br></br>
3. 일반회원 및 비회원은 다음 행위를 하여서는 안 됩니다.<br></br>
① 신청 또는 변경 시 허위내용의 등록<br></br>
② 타인의 정보도용<br></br>
③ 회사의 임직원, 운영자, 기타 관계자를 사칭하는 행위<br></br>
④ 회사가 게시한 정보를 변경하는 행위<br></br>
⑤ 회사가 금지한 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시 행위<br></br>
⑥ 회사가 제공 또는 승인하지 않은 컴퓨터 프로그램이나 기기 또는 장치를 제작, 배포, 이용, 광고하는 행위<br></br>
⑦ 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해 행위<br></br>
⑧ 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br></br>
⑨ 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 회사에 공개 또는 게시하는 행위<br></br>
⑩ 회사의 동의 없이 영리, 영업, 광고, 정치활동, 브로커제안, 장난 등을 목적으로 서비스를 사용하는 행위<br></br>
⑪ 다량의 계정 생성 및 서비스 가입·탈퇴의 반복 및 이와 유사한 활동 등 서비스의 정상적인 이용방법과 다른 방법으로 회사의 정상적인 서비스 제공을 방해하는 행위<br></br>
⑫ 회사의 임직원(고객 응대직원 포함)에게 욕설, 폭언, 모욕, 협박, 폭행, 성희롱, 성추행 등을 포함한 신체적·정신적 고통을 유발하는 행위, 서비스 이용과정에서 이유 없는 잦은 연락, 소란, 협박, 인과관계가 입증되지 않은 피해에 대한 보상 요구 등을 통해 고의로 서비스 운영을 방해하는 행위<br></br>
⑬ 회사가 제공하는 클릭검색 서비스의 선택지 내용 및 답변을 무단복제하여 외부로 유출하는 행위<br></br>
⑭ 회사가 제공하는 서비스의 지적재산권을 침해하는 행위<br></br>
⑮ 기타 관련 법령에서 금지하거나 선량한 풍속 기타 사회통념상 허용되지 않거나 부당한 행위<br></br>
4. 일반회원 및 비회원은 관계법, 본 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 확인하고 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.<br></br>
5. 일반회원은 변호사회원으로부터 받은 답변의 내용을 회사 및 변호사회원의 사전 승낙 없이 복제·편집·전시·전송·배포·판매·방송·공연하는 등의 행위를 하여서는 안 됩니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제11조. 변호사회원의 권리</strong><br></br>
1. 변호사회원은 일반회원에게 무료 또는 유료서비스를 제공할 수 있습니다.<br></br>
2. 변호사회원은 광고, 브로커제안, 장난 등 서비스의 취지에 부합하지 않는 목적으로 상담글을 게시하는 일반회원에 대하여 신고를 할 수 있으며 회사는 적절한 조치를 취해야 합니다.<br></br>
3. 변호사회원은 일반회원이 서비스에서 얻은 답변에 링크된 변호사회원의 프로필에 변호사회원의 홈페이지에 게시된 사실과 다르지 않은 범위 내에서 자신의 프로필 및 정보를 작성할 수 있습니다.<br></br>
4. 변호사회원은 일반회원이 서비스에서 얻은 답변에 링크된 변호사회원의 프로필에 변호사회원의 홈페이지를 게시하고 일반회원에게 서비스를 제공할 수 있습니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제12조. 변호사회원의 의무</strong><br></br>
1. 변호사회원은 일반회원을 위하여 자격시험종류 및 기수, 주요학력 및 경력, 소속 로펌, 지역 등 최소한의 정보를 공개하여야 합니다.<br></br>
2. 변호사회원은 회사에게 전항의 정보를 전달함에 있어 진실에 부합하게 하여야 하고, 회사가 관련 증명서류를 요청할 경우 이에 응하여야 합니다.<br></br>
3. 변호사회원은 회사가 제공하는 서비스의 지적재산권을 침해하는 행위를 하여서는 안 됩니다.<br></br>
4. 변호사회원은 일반회원과의 상담내용에 대하여 비밀준수의무를 집니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제13조. 회사의 권리</strong><br></br>
1. 회사는 서비스의 신뢰도를 위하여 이용자에게 온라인 상의 본인인증 절차를 거치도록 요구할 수 있습니다.<br></br>
2. 회사가 제공하는 서비스 및 콘텐츠에 대한 지적재산권은 회사에 귀속됩니다.<br></br>
3. 회원의 게시물 및 콘텐츠는 게시와 동시에 회사가 이용권을 취득하며, 회사가 취득한 이용권의 이용기간은 특별한 사유가 없는 한 영구로 합니다.<br></br>
4. 회사는 서비스의 향상을 위하여 일반회원의 상담분야 정보 및 클릭한 선택지 정보, 변호사회원의 답변 내용, 일반회원과 변호사회원의 질문과 답변 내용을 가명처리하여 통계, 과학적 연구, 공익적 기록보존 등에 활용할 수 있습니다. 또한, 서비스에서 클릭한 선택지 정보, 작성된 질문과 답변 등의 콘텐츠를 익명처리한 뒤 뉴스, 포털사이트 등과의 제휴와 협약 등을 통해 전송하거나 게시하도록 할 수 있습니다.<br></br>
5. 회사는 서비스 제공의 편의 등을 위하여 회원이 서비스 내에 클릭한 선택지 정보와 작성한 질문의 내용을 외부 API 서비스에 일시적으로 송신 및 복제되도록 할 수 있습니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제14조. 회사의 의무</strong><br></br>
1. 회사는 관계법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.<br></br>
2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다. 회사는 이 약관 및 개인정보처리방침에서 정한 경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다.<br></br>
3. 회사는 서비스이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 회원에게 처리과정 및 결과를 전달합니다. 다만, 즉시 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 통보하여야 합니다.<br></br>
4. 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 결함 및 장애 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제15조. 서비스의 제공 등</strong><br></br>
1. 서비스는 연중무휴, 1일 24시간 제공을 원칙으로 합니다. 단, 회사는 회사 정책, 관계법령에 따라 개별 서비스 및 회원 별로 이용가능시간, 이용가능횟수 등을 제한할 수 있습니다.<br></br>
2. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다.<br></br>
3. 제1항 및 제2항의 경우 회사는 제9조에 정한 방법으로 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.<br></br>
4. 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제16조. 서비스의 변경</strong><br></br>
1. 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.<br></br>
2. 회사는 서비스가 변경되는 경우 변경되는 서비스 내용 및 제공 일자가 예측되는 경우 사전에 사이트를 통하여 공지하거나 이용자에게 통지합니다. 다만, 회사가 통제할 수 없거나 예측할 수 없는 사유로 인하여 사전 공지 또는 통지가 불가능한 경우에는 사후 공지로 갈음할 수 있습니다.<br></br>
3. 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제17조. 정보의 제공 및 광고의 게재</strong><br></br>
1. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 거래 관련 정보, 고객 문의 등에 대한 회신 등의 정보를 공지사항, 전자우편, SMS 등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 언제든지 전자우편 등을 통하여 수신 거절을 할 수 있습니다.<br></br>
2. 회사는 "정보통신망이용촉진및정보보호등에관한법률"등 관계 법령에 따라 적법한 절차에 따라 회원에게 광고를 제공할 수 있습니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제18조. 이용자의 개인정보보호</strong><br></br>
1. 회사는 개인정보보호와 관련된 자세한 사항을 개인정보처리방침으로 정하고 이를 전자적 표시형태로 제공합니다.<br></br>
2. 회사는 이용자의 개인정보에 대하여 철저한 보안을 유지하여야 하며 서비스 운영 및 개선에만 사용하여야 하고, 이용자의 동의 없이 목적 외로 이용하거나 제3자에게 제공하지 않습니다. 다만, 다음 각호에 해당하는 경우에는 예외로 합니다.<br></br>
① 개인정보보호법에 따라 가명정보 처리를 통해 활영하는 경우<br></br>
② 관계법령에 의해 수사상의 목적으로 관계기관으로부터 요청이 있는 경우<br></br>
③ 방송통신심의위원회의 요청이 있는 경우<br></br>
④ 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우<br></br>
3. 회사는 이용자의 개인정보 관리를 위하여 이용자의 개인정보를 제3자에게 위탁할 수 있습니다.<br></br>
4. 회사는 이용자의 귀책사유로 인하여 노출된 개인정보에 대하여서는 일체의 책임을 지지 않습니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제19조. 서비스 게시물의 등록 및 관리</strong><br></br>
1. 회원은 본 약관에서 정하는 범위 내에서 자율적으로 게시물을 작성할 수 있습니다.<br></br>
2. 회원의 게시물이 정보통신망법 및 저작권법 등 법령에 위반하는 내용을 포함하는 경우, 권리자는 법령이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 법령에 따라 조치를 취하여야 합니다.<br></br>
3. 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.<br></br>
4. 본 조에 따른 세부절차는 정보통신망법 및 저작권법이 규정한 범위 내에서 회사가 정한 운영정책에 따릅니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제20조. 서비스 게시물의 저작권</strong><br></br>
1. 서비스에서 회원이 작성한 모든 게시물 중에 저작물의 경우 저작권의 보호 대상이 됩니다.<br></br>
2. 타인의 지적재산권에 속하는 대상물을 이용할 경우에는 반드시 해당 권리자의 사전동의를 받아야 합니다.<br></br>
3. 회원이 서비스 내에 공개한 게시물의 내용은 포털사이트 등 인터넷의 검색 결과 내기 서비스 프로모션, 뉴스 콘텐츠 등 기타 서비스에 인용되거나 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 편집되어 게시될 수 있습니다. 다만 이 경우, 회원은 언제든지 회사에 전화, 팩스, 전자우편 등을 통해 연락하거나 또는 서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 수정 등의 조치를 취할 수 있습니다.<br></br>
4. 회원이 서비스 내에 게시한 게시물의 내용은 본 약관 제13조 제5항의 내용에 의해 외부 API 서비스에 일시적으로 송신 및 복제될 수 있습니다.<br></br>
5. 회사는 본조 제3항 및 제4항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 회원의 동의를 얻어야 합니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제21조. 회원 및 제3자의 권리 보호</strong><br></br>
1. 회원은 타인의 저작권을 포함한 지적재산권 및 인격권 등 기타 권리를 침해해서는 안 됩니다. 만일 이와 같은 내용의 게시물로 인해 발생하는 결과에 대한 모든 책임은 회원 본인에게 있습니다.<br></br>
① 저작권자의 명시적 동의 없이 타인의 저작물의 전부 또는 일부를 복제, 배포, 전송 등의 방법으로 이용하는 것은 저작권자의 권리를 침해하는 행위이므로 복제 등의 방법으로 타인의 저작권을 침해한 게시물을 서비스에 게재 또는 등록하여서는 안 됩니다.<br></br>
② 회원은 공표된 저작물의 보도·비평·교육·연구 등을 위하여 정당한 범위 안에서 공정한 관행에 합치되게 이를 인용할 수 있으며, 반드시 출처를 명시해야 합니다. 하지만 인용이 저작자의 저작 인격권을 침해하는 것이어서는 안 되며 이러한 경우 출처를 밝히더라도 저작권 침해에 해당될 수 있습니다.<br></br>
2. 회원의 게시물에 대하여 제3자로부터 저작권 및 기타 권리의 침해로 소송 및 기타 법률적 이의가 제기된 경우 해당 회원은 변호사 비용을 포함하여 권리침해로 야기되 제반법률문제를 해결하기 위한 비용을 부담하는 등 기타 조치로 회사를 보호할 의무가 있습니다.<br></br>
3. 회원의 게시물에 대하여 제3자로부터 저작권 및 기타 권리의 침해로 이의가 제기된 경우 회사는 당해 게시물을 임의 삭제할 수 있으며, 이의를 제기한 자와 게시물 등록자 간에 소송, 합의 등을 통해 당해 게시물에 관한 법적 문제가 종결된 후 이를 근거로 회사에 신청이 있는 경우에만 상기 임시 삭제된 게시물은 다시 등록될 수 있습니다.<br></br>
4. 회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 전송, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나, 목적에 관계없이 제3자에게 이용하게 해서는 안 됩니다.<br></br>
5. 회사의 서비스에 게재 또는 등록된 회원의 게시물을 제3자가 사용하고자 할 경우에는 반드시 해당 회원의 명시적 동의를 얻은 뒤 사용해야 합니다. 단, 본 약관 제13조 제4항에 따라 상담글 등의 콘텐츠가 뉴스 등의 포털사이트 등에 전송, 게시되는 경우에는 그러하지 아니합니다.<br></br>
6. 서비스 상의 게시물은 회원 간의 자율적인 응답으로 회사는 게시물에 대한 정확성에 대해서는 보증을 하지 않습니다.<br></br>
7. 회사는 회원들의 게시물 이용에 따른 동의 절차를 중개하거나 그 과정에 개입하지 않습니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제22조. 저작권 침해 게시물의 게시중단 요청</strong><br></br>
회원은 저작권 침해에 대해 더 궁금한 내용이 있거나 권리자 본인의 저작권을 침해한 게시물을 신고하려는 경우 전화, 팩스, 전자우편 등을 통해 문의 또는 신고할 수 있습니다. 회사는 게시중단 요청된 게시물에 대해서는 최대 24시간 이내에 게시중단 처리를 완료하고 그 결과를 통보합니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제23조. 회원의 해제, 해지 등</strong><br></br>
1. 회원은 언제든지 서비스 화면의 고객센터 또는 내 정보 관리 메뉴 등을 통하여 이용계약 해지 신청(회원탈퇴)을 할 수 있습니다. 회원이 회원탈퇴를 신청한 경우 회사는 회원 본인 여부를 확인할 수 있으며, 관계법규 등이 정하는 바에 따라 이를 즉시 처리합니다. 단, 회사는 이용자의 회원가입 후 일정시간 동안 서비스 부정이용 방지 등의 사유로 즉시 탈퇴를 제한할 수 있습니다.<br></br>
2. 전항에도 불구하고, 회사는 개별 서비스 약관 등 내 규정, 이용자의 서비스 부정이용 방지 등을 사유로 즉시 탈퇴를 제한할 수 있습니다.<br></br>
3. 회원이 서비스 이용계약을 해지할 경우, 회사가 제공하는 서비스의 이용계약이 일괄적으로 해지됩니다.<br></br>
4. 회원이 서비스 이용계약을 해지할 경우, 관계법 및 개인정보처리방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 계정정보를 포함한 모든 데이터는 소멸됩니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제24조. 이용제한 및 회원자격 박탈 등</strong><br></br>
1. 회사는 일반회원 및 변호사회원이 이 약관의 의무를 위배하거나 서비스의 정상적인 운영을 방해한 경우, 현행법령 위반 및 고의 또는 중대한 과실로 회사에 손해를 입힌 경우에는 아이디(ID)의 로그인을 제한하거나 이용계약을 해지 할 수 있습니다.<br></br>
2. 회사는 다음 각 호의 경우 일반회원의 서비스 이용기간을 제한하거나 회원자격을 예고 없이 박탈할 수 있습니다.<br></br>
① 회원의 개인신상정보가 사실과 다를 경우(온라인 신청 양식의 기재사항에 허위, 기재누락, 도용, 오기가 있는 경우)<br></br>
② 타인에게 아이디를 양도, 대여한 경우<br></br>
③ 다른 이용자의 아이디를 부정 사용한 경우<br></br>
④ 서비스를 통하여 취득한 다른 회원의 정보를 수집, 저장, 양도하거나 중개에 이용한 경우<br></br>
⑤ 불법정보, 유해정보 또는 회사로부터 허가받지 않은 광고, 브로커제안, 장난글 등을 게시한 경우<br></br>
⑥ 변호사회원으로부터 받은 상담의 내용을 회사 및 변호사회원의 사전 승낙 없이 복제·편집·전시·전송·배포·판매·방송·공연하는 등의 행위를 한 경우<br></br>
⑦ 회사 직원, 운영자 등을 포함한 타인을 사칭한 경우<br></br>
⑧ 서비스의 운영에 상당한 지장을 주거나 줄 우려가 있는 경우<br></br>
⑨ 본 약관을 포함하여 회원에게 안내된 기타 회사가 정한 규정 또는 이용조건을 위반한 경우 및 이용 신청자가 본 약관 등에 의하여 이전에 회원자격을 상실한 사실이 있는 경우(다만 회원자격 상실 후 6개월이 경과한 자로서 회사로부터 회원 재가입 승낙을 얻은 경우는 예외로 합니다)<br></br>
⑩ 스토킹 등 다른 회원을 괴롭히는 행위를 한 경우<br></br>
⑪ 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 컴퓨터 바이러스, 기타 다른 컴퓨터코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 전자우편으로 배포하는 행위를 한 경우<br></br>
⑫ 법정연령이 만 14세 미만의 자인 경우<br></br>
⑬ 기타 법령에 위배되는 행위를 한 경우<br></br>
3. 회사는 다음 각 호의 경우 변호사회원의 서비스 이용기간을 제한하거나 회원자격을 예고 없이 박탈할 수 있습니다.<br></br>
① 회원의 개인신상정보가 사실과 다를 경우<br></br>
② 타인에게 아이디를 양도, 대여한 경우<br></br>
③ 다른 이용자의 아이디를 부정 사용한 경우<br></br>
④ 불법정보, 유해정보 또는 회사로부터 허가받지 않은 광고, 브로커제안, 장난글 등을 게시한 경우<br></br>
⑤ 회사 직원, 운영자 등을 포함한 타인을 사칭한 경우<br></br>
⑥ 서비스의 운영에 상당한 지장을 주거나 줄 우려가 있는 경우<br></br>
⑦ 본 약관을 포함하여 기타 회사가 정한 규정 또는 이용조건을 위반한 경우<br></br>
⑧ 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 컴퓨터 바이러스, 기타 다른 컴퓨터코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 전자우편으로 배포하는 행위를 한 경우<br></br>
⑨ 기타 법령에 위배되는 행위를 한 경우<br></br>
4. 회사가 이용계약을 해지하는 경우, 해지사유와 해지일을 제9조에 따라 회원에게 통보합니다.<br></br>
5. 회사의 이용제한에 정당한 사유가 있는 경우에 회사는 이용제한으로 인하여 회원이 입은 손해를 배상하지 않습니다.<br></br>
6. 회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.<br></br>
7. 회사는 "정보통신망이용촉진및정보보호등에관한법률" 및 동법 시행령에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 보호하기 위해 개인정보 파기 등 필요한 조치를 취합니다. 또한, 회사는 객관적으로 계정정보 도용 피해가 우려되는 경우 또는 회원이 계속해서 1년 이상 로그인하지 않을 경우에는 회원정보의 보호 및 운영의 효율성을 위해 임시조치, 이용제한, 계정정보 삭제 등 필요한 조치를 취할 수 있습니다. 본 항에 따른 조치가 취해지는 경우, 회사는 조치일 30일 전까지 개인정보 파기 등 필요한 조치가 취해진다는 사실, 일시 및 항목을 명시하여 제9조에 따라 통지합니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제25조. 면책조항</strong><br></br>
1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.<br></br>
2. 회사는 회원의 귀책사유로 인한 서비스 이용의 중지, 이용장애 및 계약해지에 대하여는 책임을 지지 않습니다.<br></br>
3. 회사는 일반회원이 서비스와 관련하여 게시 또는 제공한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.<br></br>
4. 회사는 변호사회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여 변호사회원이 기존에 회사에 제출한 서면자료가 위조, 변조되거나 기타 사유로 회사가 변호사회원의 정보가 허위임을 인지하지 못한 경우에 대하여는 책임을 지지 않습니다.<br></br>
5. 회사는 일반회원과 변호사회원 간의 상담을 진행하는 과정에서 발생한 분쟁에 대해서는 개입할 수 없고 이로 인한 손해를 배상할 책임이 없습니다.<br></br>
6. 회사는 일반회원과 변호사회원 간의 상담 선택, 상담 내용, 그 밖의 연락이나 거래, 결제에 일절 관여하지 않고 분쟁에 개입할 수 없으며 이로 인한 손해를 배상할 책임이 없습니다.<br></br>
7. 회사는 회원 간 또는 회원과 제3자 상호간에 서비스 및 콘텐츠를 매개로 하여 발생한 분쟁 등에 대하여 책임을지지 않습니다.<br></br>
8. 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.<br></br>
9. 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하는 경우, 해당 위반자는 회사에 발생하는 모든 손해를 배상하여야 하며, 동 손해로부터 회사를 면책시켜야 합니다.<br></br>
<br></br>
<br></br>
<br></br>
<strong>제26조. 준거법 및 재판관할</strong><br></br>
1. 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.<br></br>
2. 회사와 회원간 발생한 분쟁에 관한 재판관할은 민사소송법 상의 관할규정에 따릅니다.<br></br>
<br></br>
<br></br>
<br></br>
[부칙] 본 약관은 2023년 10월 10일부터 시행됩니다.<br></br>
<br></br>
변경 이력<br></br>
2023년 10월 10일 제정<br></br>
      </Text>
    </VFlex>
  );
}

export default TermsOfService;
